<script setup>
import { ref } from "vue";
import NavbarDesktop from "@/components/common/navbars/components/NavbarDesktop.vue";
import NavbarTablet from "@/components/common/navbars/components/NavbarTablet.vue";
import NavbarPhone from "@/components/common/navbars/components/NavbarPhone.vue";
import { useBreakpoints } from 'maz-ui';

const props = defineProps({
    showNotifications: ref(Boolean),
    animateToWorkfiles: Number
})

const breakpoints = {
  'sm': '576px',
  'md': '768px',
  'lg': '1025px'
}
const { isLargeScreen, isMediumScreen, isSmallScreen } = useBreakpoints({
  breakpoints,
  initialWidth: 0,
  mediumBreakPoint: 'sm',
  largeBreakPoint: 'lg',
});

</script>

<template>
  <NavbarPhone @animateToWorkfiles="props.animateToWorkfiles" v-if="!$route.meta.hideNavbar && isSmallScreen" />
  <NavbarTablet @animateToWorkfiles="props.animateToWorkfiles" v-else-if="!$route.meta.hideNavbar && isMediumScreen" />
  <NavbarDesktop @animateToWorkfiles="props.animateToWorkfiles" v-else-if="!$route.meta.hideNavbar && isLargeScreen" />
</template>

<style lang="scss" scoped></style>
