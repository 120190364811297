<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { useUserStore } from "@/stores/user.js";
import LocalStorage from '@/controller/LocalStorage.js';
import FormController from "@/controller/FormController.vue";
import { useUserPermission } from '@/stores/userPermission.js';
import { useGlobalStore } from '@/stores/global.js';
import { yautyDefaultBranding, hexToHSL, hslAdjust } from "@/services/api/Themes.js";
import { setBranding } from "@/utils/Branding.js";
import MazInput from 'maz-ui/components/MazInput';
import YBtn from '@/components/common/form/YBtn/YBtn.vue';
import { useToast } from 'maz-ui'
const envelope = '/assets/login/icons/envelope.svg';
const lock = '/assets/login/icons/lock.svg';
const globalStore = useGlobalStore();
const props = defineProps({
  email: String,
  emailDisabled: Boolean
});

const { t } = useI18n();
const userStore = useUserStore();
const router = useRouter();
const email = ref("");
const emailDisabled = ref(false);
const password = ref("");

const errorMsg = ref('');
const showError = ref(false);
const loaderButton = ref(false);
const disabledButton = ref(true);
const errorEmail = ref(false);
const errorPasssword = ref(false);
const userPermissionStore = useUserPermission();
const i18n = useI18n();
const locale = ref(i18n.locale)
const branding = ref(LocalStorage.getBranding());
const rootStyle = document.documentElement.style;
const toast = useToast()
// const authStore = useAuthStore();
if(props.email) {
  email.value = props.email;
  email.emailDisabled = props.emailDisabled
}

function onChangeInput(name) {
  if(name == 'email') {
    errorEmail.value = false;
  } else {
    errorPasssword.value = false;
  }
}

function showToast (msg,options) {
    const toastMessage = toast.error(msg, options);
    setTimeout(() => {
      toastMessage.close()
    }, 2000)
}
function login() {
  showError.value = false;
  errorMsg.value = "";

  loaderButton.value = true;
  disabledButton.value = "";

  errorEmail.value = false;
  errorPasssword.value = false;

  if (checkLogin()) {
    loaderButton.value = true;
    disabledButton.value = "disabled";
    doLogin();
  } else {
    loaderButton.value = false;
    disabledButton.value = false;
    showError.value = true;
    showToast(errorMsg.value, { position: 'top-right', timeout: 2000 })
  }
}
function checkLogin() {
  let valid = true;
  if (FormController.notEmpty(email.value)){
    errorMsg.value = t("field.fill_all_fields");
    errorEmail.value = true;
    valid = false;
  }
  if (FormController.notEmpty(password.value)){
    errorMsg.value = t("field.fill_all_fields");
    errorPasssword.value = true;
    valid = false;
  } 
   
  return valid;
}
function doLogin() {
  return userStore
    .fetchLoginUser({ email: email.value.trim(), password: password.value })
    .then((res) => {
      if(res) {
        if (res.token) {
          let now = new Date();
          let expiresIn = 35940000;
          let expires = new Date(now.getTime() + expiresIn);
          let tokenExpires = expires.getTime();
          const yautyData = JSON.stringify({
            id: res.id,
            name: res.username ? res.username : email.value,
            k: res.token,
            uid: res.uuid,
            time: new Date().getTime(),
            email: email.value,
            isNorthgate: res.isNorthgate,
            tokenLife: res.expiresIn ? res.expiresIn : expiresIn,
            tokenExpires: res.expirationDate ? res.expirationDate * 1000 : tokenExpires,
            // tokenExpires: new Date().getTime() + 10000,
            organizationId: res.organizationId
          });
          let brandParams = {
            color: res.color ? res.color : yautyDefaultBranding.PRIMARY_COLOR,
            secondary_color: res.secondaryColor ? res.secondaryColor : yautyDefaultBranding.SECONDARY_COLOR,
            tertiary_color: res.tertiaryColor ? res.tertiaryColor : yautyDefaultBranding.TERTIARY_COLOR,
            defaultLoginImage: res.defaultLoginImage ? res.defaultLoginImage : '',
            defaultLoginLogo: res.defaultLoginLogo ? res.defaultLoginLogo : '',
            logo: res.logo ? res.logo : '',
            secondary_logo: res.secondaryLogo ? res.secondaryLogo : '',
            login_image: res.loginImage ? res.loginImage : yautyDefaultBranding.LOGIN_IMAGE,
            login_logo: res.loginLogo ? res.loginLogo : yautyDefaultBranding.LOGIN_LOGO
          };
         
          if(!localStorage.getItem('yautyLanguage')){
            localStorage.setItem('yautyLanguage', res.userLang ? res.userLang.toLowerCase() : 'es');
            locale.value = res.userLang.toLowerCase();
            router.replace({ params: { lang: res.userLang } });
          }
          localStorage.setItem('yautyData', yautyData);
          userPermissionStore.fetchUserPermissions({ k: res.token })
          .then((res) => {
            router.push("/");
            localStorage.setItem('yautyBranding', JSON.stringify(brandParams));
            setBranding();
            branding.value = brandParams;
          }).catch((err) => {
            router.push("/");
          })
        } else {
          showError.value = true;
          loaderButton.value = false;
          disabledButton.value = false;
          let msgError = '';
          if (res.errors) {
            res.errors.forEach((element) => {
              msgError += t('error.' + element.error);
              if (element.field == "email") {
                errorEmail.value = true;
              } else if (element.field == "email,password") {
                errorEmail.value = true;
                errorPasssword.value = true;
              } else {
                errorPasssword.value = true;
              }
            });
            errorMsg.value = msgError;
          }
        } 
      } else {
        showError.value = true;
        loaderButton.value = false;
        disabledButton.value = false;
        errorMsg.value = t("error.CONNECTION_ERROR");
      }
    })
    .catch((err) => {
      showError.value = true;
      errorMsg.value = err;
      loaderButton.value = false;
      disabledButton.value = false;
    });
}

onBeforeRouteLeave((to, from, next) => {
  globalStore.resetSideNavKey();
  next();
})
function recoverAccount(){
  router.push('/recover-account');
}

//set initial maz-ui colors from localstore
let primary = branding.value?.color ?? '#4B49C0';
rootStyle.setProperty('--primary-color', hexToHSL(primary));
rootStyle.setProperty('--primary-color-hover', hslAdjust(primary, -10, -19));
//set initial maz-ui colors from localstore

</script>
<template>
  <div class="login__container-form">
    <form @submit.prevent="login">
      <div class="email-input-wrapper">
        <MazInput
          v-model="email"
          :label="t('field.email')"
          class="yauty-input"
          autocomplete="off"
          left-icon="banknotes"
          size="sm"
          rounded-size="full"
          contentClass="yauty-button"
          :error="errorEmail"
          @change="onChangeInput('email')"
        >
          <template #left-icon>
            <img :src="envelope" alt="" style="width:16px;">
          </template>
        </MazInput>
      </div>
      <div class="password-input-wrapper">
        <MazInput
          v-model="password"
          :label="t('field.password')"
          class="yauty-input"
          autocomplete="off"
          left-icon="banknotes"
          type="password"
          size="sm"
          rounded-size="full"
          :error="errorPasssword"
          @change="onChangeInput('password')"
          @keyup.enter="login"
        >
          <template #left-icon>
            <img :src="lock" alt="" style="width:14px;">
          </template>
        </MazInput>
      </div>

      <div class="login-button-wrapper">
        <YBtn 
            size="sm"
            color="primary" 
            :loading="loaderButton"
            :disabled="false"
            :label="t('login.login')"
            :rounded="true"
            roundedSize="full"
            type="button"
            class="yauty-button"
            @click="login">
        </YBtn>
      </div>
      <div class="register-button-wrapper" v-if="$route.name == 'login'">
        <YBtn 
            size="sm"
            to="/register"
            color="info" 
            :label="t('register.create_account')"
            :rounded="true"
            roundedSize="full"
            class="yauty-button">
        </YBtn>
      </div>

      <div class="login-pd" v-if="$route.name == 'login'">
        <label @click="recoverAccount" style="padding-right:1px"> {{ $t("login.forgot_password") }}</label>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.login__container-form {
  .email-input-wrapper{
    margin-top: 29px;
    margin-bottom: 17px;
  }
  .login-button-wrapper{
    margin-top: 52px;
    margin-bottom: 14px;
  }
  .yauty-input{
    width: 338px;
    * {
      font-family: 'CircularStd-Book';
      font-size: 16px;
    }
  }
  .yauty-button{
    width: 240px;
  }
  .login-pd{
    margin-top: 29px;
    label{
      text-align: left;
      text-decoration: underline;
      font: normal normal normal 17px/29px 'CircularStd-Book';
      letter-spacing: -0.85px;
      color: #000000;
      opacity: 1;
      position: relative;
      overflow: hidden;
      display: inline-block;
      text-decoration: none;
    }
    label:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -100%;
      width: 100%;
      height: 1px;
      background: #333;
      transition: left .8s;
    }
    label:hover:after {
      left: 0;
    }
    label:hover{
      cursor: pointer;
    }
  }
}

@media (max-width:1024px) {
  .login__container-form .login-button-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 90px;
    margin-bottom: 14px;
}
.register-button-wrapper{
  display: none;
}
.login-pd{
    position: absolute;
    top: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
      label{
      color: #ffffff9f !important;
      opacity: 1;
      font-size: 14px;
      font-family: 'CircularStd';
      text-align: center;
      font-weight: 400 !important;
    }
  }
}
@media (min-width: 577px) and (max-width: 1369px) and (orientation: portrait) {
.login-pd{
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
      label{
      color: #ffffff9f !important;
      opacity: 1;
      font-size: 23px !important;
      text-align: center !important;
      font-family: 'CircularStd';
      font-weight: 400 !important;
    }
  }
}
</style>
