<script setup>
import { computed, ref,onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import LocalStorage from '@/controller/LocalStorage.js';
import { useI18n } from "vue-i18n";
import YBtn from "@/components/common/form/YBtn/YBtn.vue";
import Notifications from '@/components/common/navbars/components/Notifications.vue';
import GlobalSearcher from "@/components/common/navbars/components/GlobalSearcher.vue";

const { t } = useI18n();
const router = useRouter();
const isHome = computed(() => router.currentRoute.value.path === '/')
const userColor = ref(LocalStorage.getColor());
let yautyData = ref("");
let userNick = ref("");
if (localStorage.getItem('yautyData')) {
  yautyData = ref(JSON.parse(localStorage.getItem('yautyData')));
  userNick = yautyData.value.name.charAt(0).toUpperCase();
}

onMounted(() => {
  // console.log('NavbarDesktop mounted');
});
onUnmounted(() => {
  // console.log('NavbarDesktop unmounted');
});
</script>

<template>

  <nav
    v-if="!$route.meta.hideNavbar"
    class="navbar navbar-desktop" :class="[{'hidden' : isHidden}, isHome ? 'navbar-home' : 'navbar-default']"
  >
      <div class="global-searcher-wrapper">
        <!-- <GlobalSearcher /> -->
      </div>

      <div class="menu nav-item">
            <li class="navbar__item d-flex notifications-item">
              <Notifications />
            </li>
            <li class="nav-item dropdown">
              <YBtn
                class="d-flex nav-link"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :rounded="true"
                roundedSize="full"
                :noPadding="true"
              >
                <div class="branding__link">{{ userNick }}</div>
              </YBtn>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <router-link to="/user" class="dropdown-item">{{
                  $t("user.profile")
                }}</router-link>
                <div class="dropdown-divider"></div>
                <router-link to="/logout" class="dropdown-item">{{
                  $t("logout.logout")
                }}</router-link>
              </div>
            </li>
      </div>
  </nav>
</template>

<style lang="scss" scoped>
.navbar.navbar-desktop {
  * {
    font-family: 'CircularStd-Book';
  }
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: #515151;
  border-bottom: 1px solid #f5f5f5;
  height: 61px;
  position: relative;
  &__item{
    margin-left: 1rem;
  }
  .global-searcher-wrapper{
    margin-left: calc(5dvw + 50px);
    flex-grow: 1;
  }
}

.menu{
  display: flex;
  gap: 20px;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-right: 1.2dvw;
  .nav-item{
    padding: 0 10px;
  }
  a {
    display: block;
    width: 100%;
    color: #515151;
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
    &:nth-child(3) {
      border-bottom-left-radius: calc(0.375rem - 1px);
      border-bottom-right-radius: calc(0.375rem - 1px);
    }
    &:nth-child(1) {
      border-top-left-radius: calc(0.375rem - 1px);
      border-top-right-radius: calc(0.375rem - 1px);
    }
    &:hover {
      color: #0195d8;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .dropdown-menu[data-bs-popper] {
    left: -5.7rem;
    top: 5dvh;
  }
  .dropdown-menu {
    --bs-dropdown-zindex: 1000;
    --bs-dropdown-min-width: 10rem;
    --bs-dropdown-padding-x: 0;
    --bs-dropdown-padding-y: 0rem;
    --bs-dropdown-spacer: 0.125rem;
    --bs-dropdown-font-size: 1rem;
    --bs-dropdown-color: #212529;
    --bs-dropdown-bg: #fff;
    --bs-dropdown-border-color: var(--bs-border-color-translucent);
    --bs-dropdown-border-radius: 0.375rem;
    --bs-dropdown-border-width: 1px;
    --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
    --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
    --bs-dropdown-divider-margin-y: 0rem;
    --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    --bs-dropdown-link-color: #212529;
    --bs-dropdown-link-hover-color: #1e2125;
    --bs-dropdown-link-hover-bg: #e9ecef;
    --bs-dropdown-link-active-color: #fff;
    --bs-dropdown-link-active-bg: #0d6efd;
    --bs-dropdown-link-disabled-color: #adb5bd;
    --bs-dropdown-item-padding-x: 0.9rem;
    --bs-dropdown-item-padding-y: 0.9rem;
    --bs-dropdown-header-color: #6c757d;
    --bs-dropdown-header-padding-x: 1rem;
    --bs-dropdown-header-padding-y: 1rem;
    position: absolute;
    z-index: var(--bs-dropdown-zindex);
    min-width: var(--bs-dropdown-min-width);
    padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
    margin: 0;
    font-size: var(--bs-dropdown-font-size);
    color: var(--bs-dropdown-color);
    text-align: left;
    list-style: none;
    background-color: var(--bs-dropdown-bg);
    background-clip: padding-box;
    border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
    border-radius: var(--bs-dropdown-border-radius);
  }
  .branding__link {
    color: white;
    background-color: v-bind(userColor);
    height: 32px;
    width: 32px;
    text-align: center;
    border-radius: 30px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }  
}
</style>
