<script setup>
import { computed, ref, onMounted, onUnmounted, watch  } from "vue";
import { useRouter } from "vue-router";
import { useCommunicatorStore } from '@/stores/communicator.js';
import LocalStorage from '@/controller/LocalStorage.js';
import { isAuthorized } from "@/utils/Authorization.js";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useGlobalStore } from '@/stores/global.js';
import Notifications from '@/components/common/navbars/components/Notifications.vue';
import GlobalSearcher from "@/components/common/navbars/components/GlobalSearcher.vue";
const props = defineProps({
    animateToWorkfiles: Number
})
const router = useRouter();
const globalsStore = useGlobalStore();
const communicatorStore = useCommunicatorStore();
const { getLastBrandingChanges: lastBrandingChanges } = storeToRefs(communicatorStore);
const yautyLogo = '/assets/logos/yauty-logo.svg';
const profile = '/assets/home/profile.svg';
const path =  router.currentRoute.value;
const homeIcon = '/assets/home/home.svg';
const orIcon = '/assets/home/reparacion.svg';
const carIcon = '/assets/home/car.svg';
const configIcon = '/assets/home/ajustes.svg';
const adminIcon = '/assets/svg/private.svg';
const exit = '/assets/home/exit.svg';
const bell = '/assets/layout/bell.svg';
const userColor = ref(LocalStorage.getColor());
const userSecondaryColor = ref(LocalStorage.getColorSecondary());
const userTertiaryColor = ref(LocalStorage.getColorTertiary());
const selectedColor = ref(LocalStorage.getColorSelected());
const userSecondaryLogo = ref(LocalStorage.getUserSecondaryLogo());
const logoSrc = computed(() => {
  return userSecondaryLogo.value && userSecondaryLogo.value !== 'https://yauty.com/public/assets/login/login_logo.png' 
    ? userSecondaryLogo.value 
    : yautyLogo;
});

const selectedColorHover = ref(LocalStorage.getColorSelectedHover());
const phoneMenu = ref(1);
const { t } = useI18n();
const isHome = computed(() => router.currentRoute.value.path === '/');
const isMenuOpen = ref(false);
const showGlobalSearchInput = ref(false);

const toggleMenu = () => {
  showGlobalSearchInput.value = false;
  if (isMenuOpen.value) {
    isMenuOpen.value = false;
    setTimeout(() => {
      isFullyCollapsed.value = true;
    }, 300); 
  } else {
    isFullyCollapsed.value = false;
    isMenuOpen.value = true;
  }
};

let yautyData = ref("");
let userNick = ref("");
if (localStorage.getItem('yautyData')) {
    yautyData = ref(JSON.parse(localStorage.getItem('yautyData')));
    userNick = yautyData.value.name.charAt(0).toUpperCase();
}
const isWorkfileRoute = computed(() => {
  return router.currentRoute.value.path.startsWith('/workfile/');
});
const yautyLogoStyle = computed(() => {
  if (isWorkfileRoute.value) {
    if (isMenuOpen.value) {
      return {
        display: 'block'
      };
    } else if(!isMenuOpen.value && isFullyCollapsed.value){
      return {
        display: 'none'
      };
    }
  }
});
const burgerIconColor = computed(() => {
  if (isWorkfileRoute.value && !isMenuOpen.value && isFullyCollapsed.value) {
    return '#262453'; 
  } else if (isWorkfileRoute.value && isMenuOpen.value) {
    return '#fff'; 
  } else {
    return '#fff';
  }
});

const isFullyCollapsed = ref(true);

const navbarStyle = computed(() => {
  if (isWorkfileRoute.value) {
    if (isMenuOpen.value) {
      return {
        background: `transparent linear-gradient(90deg, ${userTertiaryColor.value} 0%, ${userColor.value} 100%) 0% 0% no-repeat padding-box`,
        transition: 'background-color 0.3s ease-in-out'
      };
    } else if (isFullyCollapsed.value){
      return {
        background: 'transparent',
        transition: 'background-color 0.3s ease-in-out',
      };
    }else {
      return {
        background: `transparent linear-gradient(90deg, ${userTertiaryColor.value} 0%, ${userColor.value} 100%) 0% 0% no-repeat padding-box`,
        transition: 'background-color 0.3s ease-in-out',
      };
    }
  }
  if (isHome.value) {
    return {
      background: `${userColor.value} !important`,
      transition: 'background-color 0.3s ease-in-out'
    };
  }
  return {
    background: `transparent linear-gradient(90deg, ${userTertiaryColor.value} 0%, ${userColor.value} 100%) 0% 0% no-repeat padding-box`,
    transition: 'background-color 0.3s ease-in-out'
  };
});
const phoneSlider = ref(null);
function animatePhoneSlider(index, toggle=true){
  const itemHeight = 62;
  const newPosition = index * itemHeight;
  if (phoneSlider.value){
    phoneSlider.value.style.transform = `translateY(${newPosition}px)`;
  }
    if(toggle){
    setTimeout(() => {
      toggleMenu();
    }, 200);
  }
}

const isHidden = ref(false);
let lastScrollTop = 0;
const handleScroll = (event) => {
  const scrollTop = event.target.scrollTop || 0;

  if (scrollTop > lastScrollTop){
    isHidden.value = true;
  }else{
    isHidden.value = false;
  }

  lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
};

const brandingChanged = computed(() => lastBrandingChanges.value);
const viewChanged = computed(() => globalsStore.getCurrentView);

function toggleGlobalSearchInput() {
  isMenuOpen.value = false;
  showGlobalSearchInput.value = !showGlobalSearchInput.value;
}
watch(brandingChanged, (value) => {
  if(value){
    userColor.value = LocalStorage.getColor();
    userSecondaryColor.value = LocalStorage.getColorSecondary();
    userTertiaryColor.value = LocalStorage.getColorTertiary();
    selectedColor.value = LocalStorage.getColorSelected();
    selectedColorHover.value = LocalStorage.getColorSelectedHover(); 
    userSecondaryLogo.value = LocalStorage.getUserSecondaryLogo(); 
    // userLogo.value = LocalStorage.getUserLogo(); 
  }
});
watch(viewChanged, (value) => {
  //the only way to detect browser refresh (F5) by now
  if(value){
    switch(value){
      case 'user':
        animatePhoneSlider(0);
        break;
      case 'home':
        animatePhoneSlider(1);
        break;
      case 'vehicles':
      case 'vehicle':
        animatePhoneSlider(2);
        break;
      case 'workfiles':
      case 'workfile':
        animatePhoneSlider(3);
        break;
      case 'configuration':
      case 'configurationList':
        animatePhoneSlider(4);
        break;
      case 'notifications':
        animatePhoneSlider(5);
        break;
      case 'ifs':
        animatePhoneSlider(6);
      break;
      case 'logout':
        animatePhoneSlider(7);
      break;
    }
  }
});
watch(() => props.animateToWorkfiles, (value) => {
  if(value){
    animatePhoneSlider(3);
  }
});
watch(userSecondaryLogo, (newVal) => {
  console.log("Updated userSecondaryLogo:", newVal);
});


onMounted(() => {
  // console.log('NavbarPhone mounted');
  if(['home', 'login'].includes(path.name)){
    setTimeout(() => {
      animatePhoneSlider(1);
    }, 500);
  }
  window.addEventListener('scroll', handleScroll);
  const appContainer = document.getElementById('app');
  if (appContainer){
    appContainer.addEventListener('scroll', handleScroll);
  }
});
onUnmounted(() => {
  // console.log('NavbarPhone unmounted');
  window.removeEventListener('scroll', handleScroll);
  const appContainer = document.getElementById('app');
  if (appContainer){
    appContainer.removeEventListener('scroll', handleScroll);
  }
});

var listItems = [];
</script>

<template>
  <nav
    v-if="!$route.meta.hideNavbar"
    class="navbar" :style="navbarStyle" :class="[{'hidden' : isHidden}, isHome ? 'navbar-home' : 'navbar-default', isWorkfileRoute ? 'workfile-nav' : 'navbar-default']"
  >
    <div class="navbar__brand yauty-logo" @click="animatePhoneSlider(1, false)">
        <router-link
            to="/"
            class="nav-link"
            title=""
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            data-bs-original-title="Home"
        >
            <span>
            <img :style="yautyLogoStyle" :src="logoSrc" style="width:22dvw;height:auto"/>
            </span>
        </router-link>
    </div>
    <div class="navbar__toggle">
        <!-- <svg @click="toggleGlobalSearchInput" width="16" height="16" data-v-56e02ab8="" class="svg-inline--fa fa-magnifying-glass searchbox-icon" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="magnifying-glass" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path class="" :fill="burgerIconColor" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path></svg> -->
        <svg @click="toggleMenu" class="icon" v-if="!isMenuOpen" xmlns="http://www.w3.org/2000/svg" width="32.273" height="22.515" viewBox="0 0 32.273 22.515">
            <g id="Grupo_6197" data-name="Grupo 6197" transform="translate(-302.5 -17.309)">
                <line id="Línea_805" data-name="Línea 805" x2="29.273" transform="translate(304 18.809)" fill="none" :stroke="burgerIconColor" stroke-linecap="round" stroke-width="3"/>
                <line id="Línea_806" data-name="Línea 806" x2="29.273" transform="translate(304 28.567)" fill="none" :stroke="burgerIconColor" stroke-linecap="round" stroke-width="3"/>
                <line id="Línea_807" data-name="Línea 807" x2="29.273" transform="translate(304 38.324)" fill="none" :stroke="burgerIconColor" stroke-linecap="round" stroke-width="3"/>
            </g>
        </svg>
        <svg @click="toggleMenu" class="icon" v-else xmlns="http://www.w3.org/2000/svg" width="27.784" height="25.383" viewBox="0 0 27.784 25.383">
            <g id="Grupo_6112" data-name="Grupo 6112" transform="translate(-295.883 -21.381)">
                <line id="Línea_805" data-name="Línea 805" x2="23.547" y2="20.847" transform="translate(298 23.797)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
                <line id="Línea_807" data-name="Línea 807" y1="21.145" x2="23.067" transform="translate(298.481 23.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
            </g>
        </svg>
    </div>
    <ul :class="{'navbar__menu': true, 'navbar__menu--open': isMenuOpen}">
        <div class="slider" ref="phoneSlider" ></div>
        <li class="menu-item-wrapper navbar__item phone-item" @click="animatePhoneSlider(0)" ref="phoneMenu" >
            <router-link
                to="/user"
                class="route-linker"
                :class="router.currentRoute.value.name === 'user' ? 'active' : ''" >
                <div class="sidenav-item">
                    <div class="sidenav-link" >
                    <div class="item-icon">
                        <img :src="profile" class="icon-user" style="width:25px;"/>
                    </div>
                    <div class="menu-label">{{ $t('route.user') }}</div>
                    </div>
                </div>
            </router-link>
        </li>
        <li  class="menu-item-wrapper navbar__item phone-item" @click="animatePhoneSlider(1)">
            <router-link
                to="/"
                class="route-linker"
                :class="router.currentRoute.value.name === 'home' ? 'active' : ''">
                <div class="sidenav-item">
                <div class="sidenav-link" >
                    <div class="item-icon">
                    <img :src="homeIcon" style="width:25px;"/>
                    </div>
                    <div class="menu-label">{{ $t('route.home') }}</div>
                </div>
                </div>
            </router-link>
        </li>
        <li class="menu-item-wrapper navbar__item phone-item" @click="animatePhoneSlider(2)">
            <router-link
                to="/vehicles"
                class="route-linker"
                :class="
                router.currentRoute.value.name === 'vehicles' ||
                router.currentRoute.value.name === 'vehicle' ||
                router.currentRoute.value.name === 'vehicle/add' ||
                router.currentRoute.value.name === 'createAppointment'
                ? 'active'
                : ''
                ">
                <div class="sidenav-item">
                    <div class="sidenav-link">
                        <div class="item-icon">
                          <img :src="carIcon" style="width:28px;"/>
                        </div>
                        <div class="menu-label car">{{ $t('route.vehicles') }}</div>
                    </div>
                </div>
            </router-link>
        </li>
        <li  class="menu-item-wrapper navbar__item phone-item" @click="animatePhoneSlider(3)">
            <router-link
                to="/workfiles"
                class="route-linker"
                :class="
                    router.currentRoute.value.name === 'workfiles' ? 'active' : '' ||
                    router.currentRoute.value.name === 'workfile' ? 'active' : ''
                ">
                <div class="sidenav-item">
                <div class="sidenav-link">
                    <div class="item-icon">
                    <img :src="orIcon" style="width:25px;"/>
                    </div>
                    <div class="menu-label">{{ $t('route.workfiles') }}</div>
                </div>
                </div>
            </router-link>
        </li>
        <li class="navbar__item  notifications-itemphone-item" @click="animatePhoneSlider(4)">
            <router-link 
                to="/notifications" 
                class="route-linker"
                :class="router.currentRoute.value.name === 'notifications' ? 'active' : ''">
                  <div class="sidenav-item">
                  <div class="sidenav-link" >
                      <div class="item-icon">
                      <img class="bell-icon" :src="bell" style="width:28px;"/>
                      </div>
                      <div class="menu-label">{{ $t('route.notifications') }}</div>
                  </div>
                  </div>
            </router-link>
        </li>
        <div class="phone-item" v-if="isAuthorized('organizationMenu')" data-bs-trigger="hover" :data-bs-content="t('route.configuration')" :ref="el => { listItems[4] = el }">
            <li  class="menu-item-wrapper navbar__item " @click="animatePhoneSlider(5)">
                <router-link
                    to="/configuration"
                    class="route-linker"
                    :class="
                        router.currentRoute.value.name === 'configurationList' ||
                        router.currentRoute.value.name === 'configuration' ||
                        router.currentRoute.value.name === 'createInvitation' ? 'active' : ''
                    ">
                    <div class="sidenav-item">
                    <div class="sidenav-link">
                        <div class="item-icon">
                        <img :src="configIcon" style="width:25px;"/>
                        </div>
                        <div class="menu-label">{{ $t('route.configuration') }}</div>
                    </div>
                    </div>
                </router-link>
            </li>
        </div>
        <div class="phone-item" v-if="isAuthorized('yautyadmin')" data-bs-trigger="hover" :ref="el => { listItems[5] = el }">
            <li class="menu-item-wrapper navbar__item" @click="animatePhoneSlider(6)">
                <router-link
                    to="/yfs"
                    class="route-linker"
                    :class="
                    router.currentRoute.value.name === 'yfs' ? 'active' : ''
                    "
                >
                    <div class="sidenav-item">
                    <div class="sidenav-link yfs"  >
                        <div class="item-icon">
                        <img :src="adminIcon" style="width:22px;"/>
                        </div>
                        <div class="menu-label yfs">yfs</div>
                    </div>
                    </div>
                </router-link>
            </li>
        </div> 
        <li class="menu-item-wrapper navbar__item phone-item" @click="animatePhoneSlider(7)">
            <router-link
                to="/logout"
                class="route-linker"
                :class="router.currentRoute.value.name === 'logout' ? 'active' : ''">
                <div class="sidenav-item">
                <div class="sidenav-link" >
                    <div class="item-icon">
                      <img :src="exit" style="width:23px;"/>
                    </div>
                    <div class="menu-label exit">{{ $t('route.logout') }}</div>
                </div>
                </div>
            </router-link>
        </li>
    </ul>
  </nav>
  <!-- <div class="globalSearch-wrapper" :class="{'show': showGlobalSearchInput}">
    <GlobalSearcher />
  </div> -->
</template>

<style lang="scss" scoped>
.navbar {
  * {
    font-family: 'CircularStd-Book';
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #515151;
  border-bottom: 1px solid #f5f5f5;
  height: 61px;
  position: relative;
  &__toggle{
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 6;
  }
  &__menu{
    overflow: hidden;
    display: none;
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    @media (max-width: 576px) {
      max-height: 0;
      overflow: hidden;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      transition: max-height 0.3s ease-in-out;
      margin-top: 52px;
      position: absolute;
      right: 0;
      border-radius: 0px 0px 12px 12px;
      &--open{
        z-index: 6;
        max-height: 100dvh;
        margin-top: 52px;
        border-radius: 0px 0px 12px 12px;
      }
    }
  }
  &__item{
    margin-left: 1rem;
  }
  .navbar__toggle{
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: start;
    align-items: center;
    .searchbox-icon{
      color: white;
      width: 22px;
      height: 22px;
    }
  }
  .search{
    width: calc(100dvw - 2rem);
    margin-bottom: 1rem;
  }
  a {
    display: block;
    width: 100%;
    color: #515151;
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
    &:nth-child(3) {
      border-bottom-left-radius: calc(0.375rem - 1px);
      border-bottom-right-radius: calc(0.375rem - 1px);
    }
    &:nth-child(1) {
      border-top-left-radius: calc(0.375rem - 1px);
      border-top-right-radius: calc(0.375rem - 1px);
    }
    &:hover {
      color: #0195d8;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .yauty-logo{
    padding-left: 25px;
    margin-top: 10px;
    visibility: hidden;
    @media (max-width: 576px) {
      visibility: visible;
    }
  }
  .dropdown-menu[data-bs-popper] {
    left: -5.7rem;
    top: 5dvh;
  }
  .dropdown-menu {
    --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
    --bs-dropdown-item-padding-x: 0.9rem;
    --bs-dropdown-item-padding-y: 0.9rem;
    --bs-dropdown-header-color: #6c757d;
    --bs-dropdown-header-padding-x: 1rem;
    --bs-dropdown-header-padding-y: 1rem;
    position: absolute;
    z-index: var(--bs-dropdown-zindex);
    min-width: var(--bs-dropdown-min-width);
    padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
    margin: 0;
    font-size: var(--bs-dropdown-font-size);
    color: var(--bs-dropdown-color);
    text-align: left;
    list-style: none;
    background-color: var(--bs-dropdown-bg);
    background-clip: padding-box;
    border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
    border-radius: var(--bs-dropdown-border-radius);
  }
  .branding__link {
    color: white;
    background-color: v-bind(userColor);
    height: 32px;
    width: 32px;
    text-align: center;
    border-radius: 30px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.route-linker {
  &.active {
    .item-icon {
    //border-left: 4px solid v-bind(userSecondaryColor);
    padding-left: 10px;
    margin-left: 20px;
    }
    .sidenav-link{
      margin-left: 0px;
      background-color: v-bind(selectedColor);
    }
}
}

.sidenav-link{
  display: flex;
  width: 100dvw;
  gap: 22px;
  margin-left:30px;
  padding-left: 10px;
  padding-bottom: 20px;
  padding-top: 20px;
  position: relative;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.sidenav-item{
  border-bottom: 0.1px solid rgba(194, 194, 194, 0.3);
  margin-left: -15px;
}
.selected .sidenav-item{
  background-color: v-bind(selectedColor); 
}
.menu-label{
  padding: 5px 0px;
}
.menu-label.car{
  margin-left: -3px;
}
.menu-label.yfs{
  margin-left: 5px;
}
.menu-label.exit{
  margin-left: 2.5px;
}

.menu{
  position: absolute;
  top: 1dvh;
  right:0;
  display: flex;
  flex: 3;
  gap: 20px;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-right: 1.2dvw;
  @media (max-width:576px) {
    display: none;
  }
  .nav-item{
    padding: 0 10px;
  }
}

.globalSearch-wrapper{
    z-index: 1000;
    width: 100dvw;
    height: 43px;
    background-color: #fff;
    visibility: hidden;
    opacity: 0;
    scale: 0;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    transition: all 0.25s ease;
    position: absolute;
    top: 60px;
    left: 0;
    &.show{
        visibility: visible;
        opacity: 1;
        scale: 1;
    }
}
  //moviles
@media (max-width:576px){
  .slider{
    display: inline-block;
    position: absolute;
    margin-top: 0px;
    margin-left: 15px;
    left: 12px;
    width: 3px;
    height: 40px; 
    background-color: v-bind(userSecondaryColor);
    transition: transform 0.3s ease-in-out;
  }
  .navbar-home{
    .navbar__menu{
      background: transparent linear-gradient(180deg, v-bind(userColor) 0%, v-bind(userColor) 21%, v-bind(userTertiaryColor) 100%) 0% 0% no-repeat padding-box !important;
       }
    ul.navbar__menu.navbar__menu--open{
      background: transparent linear-gradient(180deg, v-bind(userColor) 0%, v-bind(userColor) 21%, v-bind(userTertiaryColor) 100%) 0% 0% no-repeat padding-box !important;
      }
  }
  .navbar-default{
    .navbar__menu{
      background: transparent linear-gradient(90deg, v-bind(userTertiaryColor) 0%, v-bind(userColor) 100%) 0% 0% no-repeat padding-box;
    }
    ul.navbar__menu.navbar__menu--open{
      background: transparent linear-gradient(90deg, v-bind(userTertiaryColor) 0%, v-bind(userColor) 100%) 0% 0% no-repeat padding-box;
    }
  }
  .navbar{
    height: 61px;
    border-bottom: none;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1000;
    position: fixed;
    width: 100dvw;
    transition: transform 0.18s ease-in-out;
    &.hidden {
      transform: translateY(-100%);
    }
    &__toggle-tablet{
      display: none;
    }
    &__toggle{
      display: block;
      position: absolute;
      right: 28px;
      top: 15px;

      .icon{
        height: 25px;
        width: 30px;
        color: white;
      }
    }
    .icon-user{
        height: 30px !important;
        color: white;
      }

      .logout-icon{
        height: 30px !important;
        font-weight: 400 !important;
        stroke-width: 2 !important;
      }
      
  }
  .sidenav-link{
    font-size: 16px;
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .sidenav-link.yfs{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  img.bell-icon {
    filter: invert(100%);
  }
}
</style>
