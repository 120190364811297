import { defineStore, acceptHMRUpdate } from 'pinia';
import { url } from '@/conf/conf.js';
import { ref } from "vue";
import { middleware } from "@/services/api/Middleware.js";
import { i18n } from "@/utils/i18n.js";
import { wfStatusUnwrapped } from "@/services/api/Mappings.js";

export const useWorkfilesStore = defineStore({
  id: "workfiles",
  state: () => ({
    workfilesData: [],
    workfileSelected: '',
    worfilesNonSelected: [],
    isLoading: true,
    isLoadingWorkfile: true,
    isLoadingMoreCards: false,
    page: 1,
    maxPages: 0,
    listPage: 1,
    totalWorkfiles: 0,
    totalShow: 10, // default
    listWorkfiles: [],
    status: null,
    filters: {
      status: '',
      orNumber: '',
      plate_vin: '',
      brand: '',
      branch: '',
      date: '',
      outOfStock: 0,
      reparationTime: 0
    },
    statusGroups: {
      "99":["99","101"],//pressupuesto
      "0":["0","7","8","14"],//cita
      "1":["1","2"],//Recepcion
      "3":["3","4","5","6","16"],//reparación
      "5":["5","100"],//pendiente
      "16":["9","16"],//acabado
      "10":["10"],//listo
      "11":["11"]//entregado
    },
    kpiStatusGroups:{
      "workshop": "1,3,10,16",//"1,2,3,4,5,6,9,10,16",
      "inProgress": "3",//"3,4,5,6,16",
      "done": "16",//"9,16",
      "prepared": "10", //10
      "pending": "5"//"5,100",
    }
  }),
  getters: {
    getStatusGroups(state) {
      return state.statusGroups;
    },
    getFilters(state) {
      return state.filters;
    },
    getWorkfiles(state) {
      return state.workfilesData;
    },
    getWorkfilesSelected(state) {
      return state.workfileSelected;
    },
    getWorkfilesNonSelected(state) {
      return state.worfilesNonSelected;
    },
    getWorkfilesLoading(state) {
      return state.isLoading
    },
    getWorkfileLoading(state) {
        return state.isLoadingWorkfile
    },
    getWorkfileLoadingMore(state) {
        return state.isLoadingMoreCards
    },    
    getTotalWorkfiles(state) {
      return state.totalWorkfiles;
    },
    getTotalWorkfilesShow(state) {
      return state.totalShow;
  },    
    getListWorkfiles(state) {
      return state.listWorkfiles;
    },
    getListPage(state) {
      return state.listPage;
    },
    getMaxPages(state) {
      return state.maxPages;
    },
    getCurrentPage(state) {
      return state.page;
    }
  },
  actions: {
    async fetchWorkfile(params) {
      this.isLoadingWorkfile = true;
      let options = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          validation_key: params.k,
          workfile_id: params.id,
          method: 'workfile',
        }),
      };
      try {
          return middleware(url, options, ['isTokenAlive'])()
          .then((response) => response.json())
          .then((data) => {
            if (data && data.errors) {
              this.isLoadingWorkfile = false;
              return data;
            }
            if (data) {
              // this.workfileSelected = this.parseWorkfilesStatusByGroup(data);
              this.isLoadingWorkfile = false;
            }
            let wf = this.parseWorkfilesStatusByGroup(data);
            if(wf && wf.data && wf.data.workfile_status_log.length > 0){
              wf.data.workfile_status_log = wf.data.workfile_status_log.map(log => {
                log.statusColor = this.getStatusColor(log.status, wf.type);
                return log;
              });

            }
            return wf;
          })
          .catch((error) => {
            console.log("Error:", error);
          });
      } catch (error) {
        console.log(error);
      }
    },
    async fetchWorkfilesCount(params) {
      let status = this.parseStatus(params.status);
      let options = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          validation_key: params.k,
          method: 'workfilesCount',
          status: status,
          outOfStock: params.outOfStock,
          reparationTime: params.reparationTime,
        }),
      };
      try {
        return middleware(url, options, ['isTokenAlive'])()
          .then((response) => response.json())
          .then((data) => data)
          .catch((error) => {
            console.log('Error:', error);
          });
      } catch (error) {
        console.log(error);
      }
    },
    async fetchSearchWorkfiles(params) {
      this.isLoading = true;
      this.listWorkfiles = [];
      if(this.filters && this.filters.status == 'null') {
        this.filters.status = null;
      }
      let parsedFilters = {...this.filters};
      let statusUnwrapped = [];
      if(parsedFilters.status && Array.isArray(parsedFilters.status)){
        parsedFilters.status.forEach(element => {
          statusUnwrapped.push(wfStatusUnwrapped(element)); 
        });
      }else{
        statusUnwrapped.push(wfStatusUnwrapped(parsedFilters.status));
      }
      parsedFilters.status = statusUnwrapped.flat(Infinity);
      let options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
            validation_key: params.k,
            limit: params.limit,
            offset: params.offset * params.limit,
            filter: parsedFilters,
            sort: params.sort,
            dir: params.dir,
            method: "workfilesSearch"
        })
      };
      try {
        middleware(url, options, ['isTokenAlive','isAuth'])()
        .then((response) => response.json())
        .then((data) => {
            if(data.errors) {
                // TODO
                this.isLoading = false;
                return data;
            } else if(data.workfiles.length>0) {
                let parsedWorkfiles = this.parseWorkfilesStatusByGroup(data.workfiles);
                this.workfilesData = parsedWorkfiles
                this.worfilesNonSelected = parsedWorkfiles
                this.listWorkfiles = parsedWorkfiles;
                this.isLoading = false;
                this.totalWorkfiles = data.count;
                this.totalShow = data.workfiles.length;
                this.listPage = params.offset + 1;
                this.page = params.offset + 1;
                this.maxPages = Math.ceil(data.count / params.limit) - 1
                if(params.resetFilters){
                  this.filters.status = '';
                  this.filters.outOfStock = 0;
                  this.filters.reparationTime = 0;
                }
            }else {
              this.worfilesNonSelected = 0;
              this.totalWorkfiles = 0;
              this.workfilesData = [];
              this.isLoading = false;
              if(params.resetFilters){
                this.filters.status = '';
                this.filters.outOfStock = 0;
                this.filters.reparationTime = 0;
              }
            }
            return data
        })
        .catch((error) => {
            console.log("Error:", error);
        });
      }
      
      catch (error) {
          console.log(error)
      }
    },
    parseStatus(status) {
      if(status !== 'null' && Array.isArray(status)) {
        // let statusArray = status.map((status) => { return this.statusGroups[status]}).flat();
        // statusArray = [...new Set(statusArray)];
        return status.toString();
      }else{
        return status;
      }
    },
    changeSelected(params, workfileId) {
      let index = 0;
      this.worfilesNonSelected.forEach((object, i) => {
        delete object.selected;
        if(object.id == workfileId) { 
          index = i;
        }
      });

      let newItem = {
        ...this.worfilesNonSelected[index],
        selected: true
      };

      this.worfilesNonSelected.splice(index, 1, newItem)

      this.fetchWorkfile(params, workfileId)
    },
    changeStatus(newStatus) {
      this.status = newStatus
    },
    getStatusColor: (status, type) => {
      let presupuesto = '#cc2c77';
      let cita = '#c5c51a';
      let recepcion = '#262453';
      let reparacion = '#4b49c0';
      let pendiente = '#e29700';
      let listo = '#8db51d';
      let entregado = '#1e9f67';
      let wfColors = {
        '0': cita,
        '1': reparacion,
        '2': reparacion,
        '3': reparacion,
        '4': reparacion,
        '5': pendiente,
        '6': reparacion,
        '7': cita,
        '8': cita,
        '9': reparacion,
        '10': listo,
        '11': entregado,
        '12': recepcion,
        '13': recepcion,
        '14': cita,
        '16': reparacion,
        '99': presupuesto,
        '100': pendiente,
        '101': presupuesto,
        '102': presupuesto
      };
      let quotColors = {
        '0': presupuesto,
        '1': presupuesto,
        '2': presupuesto,
        '3': presupuesto,
        '4': pendiente,
        '5': presupuesto,
        '6': presupuesto,
        '99': presupuesto,
        '100': pendiente,
        '101': presupuesto,
        '102': presupuesto
      };
      return type == 1 ? quotColors[status] : wfColors[status];
    },
    parseWorkfilesStatusByGroup(data) {
      const groupMembership = { //Per més info YTY-80
        '0': '0',
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '3',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9',
        '10': '10',
        '11': '11',
        '12': '12',
        '13': '13',
        '14': '14',
        '16': '16',
        '99': '99',
        '100': '100',
        '101': '101',
        '102': '102',
        '103': '103',
      }
      if(data.id){
        // if(![14,100,101,102].includes(data.status)){
        //   data.status = groupMembership[data.status] ?? data.status;
        // }
        this.addWorkfileStatusToLog(data);
        return data;
      }else{
        return data.map(workfile => {
          if(workfile.status != 100 && workfile.status != 14){
            workfile.status = groupMembership[workfile.status] ?? workfile.status;
          }
          workfile.statusColor = this.getStatusColor(workfile.status, '0');
          if (!workfile.hasOwnProperty('isExpanded')){
            workfile.isExpanded = false;
          }
          if(workfile.data.negotiation_url && workfile.data.negotiation_url.length > 0){
            let locale = i18n.global.locale;
            workfile.data.negotiation_url = workfile.data.negotiation_url.replace('/es/', `/${locale.value.toLowerCase()}/`);
          }
          this.addWorkfileStatusToLog(workfile);
          return workfile;
        });
      }
    },
    addWorkfileStatusToLog(workfile){
      if (workfile.data && workfile.data.workfile_status_log && workfile.data.workfile_status_log.length > 0) {        
        workfile.data.workfile_status_log.forEach(log => {
          log.wfStatus = workfile.status;
        });
      }

      return workfile;
    },
    expandWorkfile(workfile, index) {
      let workfileExpanded = {};
      workfile.isExpanded = true;
      workfileExpanded.isExpanded = false;
      workfileExpanded.isExpansion = true;
      workfileExpanded.html = this.buildWorkfilesListExpandedHtml(workfile);
      this.listWorkfiles.splice(index + 1, 0, workfileExpanded);
    },
    unexpandWorkfile(workfile, index) {
      workfile.isExpanded = false;
      this.listWorkfiles.splice(index + 1, 1);
    },
    buildWorkfilesListExpandedHtml(workfile){
      let html = '';
        html += '<div style="font-family:\'CircularStd-Bold\';color:#153440;gap:0.5rem;margin-bottom:0.5rem;display:flex">';
          html +=   '<div class="expansion--actions" style="flex-basis:53%">'+ i18n.global.t('actions.action') +'</div>';
          html +=   '<div class="expansion--branch" style="flex-basis:21%">'+ i18n.global.t('field.branch') +'</div>';
          html +=   '<div class="expansion--files" style="flex-basis:13%">'+ i18n.global.t('field.files') +'</div>';                        
          html +=   '<div class="expansion--files" style="flex-basis:11%">'+ i18n.global.t('field.invoices') +'</div>';                        
        html +=   '</div>';
      html +=   '<div class="expansion-separator" style="border: 1px solid #FFFFFF;width:100%;margin-bottom:1rem;">';
      html +=   '</div>';      
      html +=   '<div class="expansion-data" style="color:black;gap:0.5rem;display:flex">';
        html +=     '<div class="expansion-actions" style="flex-basis:53%">';
        if(workfile.actions.length){
          workfile.actions.forEach(action => {
            html +=     '<div class="expansion-action-description">' + action.description + '</div>';
          });
        }
        html +=     '</div>';
        html +=     '<div class="expansion-branch" style="line-height:1.2;flex-basis:21%">';
        if(workfile.data?.branch){
          html +=         '<div class="expansion-branch-name" style="font-family:\'CircularStd-Bold\';color:#000000;">' + workfile.data.branch.name + '</div>';
          html +=         '<div class="expansion-branch-address">' + workfile.data.branch.address + '</div>';
          html +=         '<div class="expansion-branch-city">' + workfile.data.branch.city + '</div>';
          html +=         '<div class="expansion-branch-state_cp">' + workfile.data.branch.state + ' ' + workfile.data.branch.postal_code + '</div>';
          html +=         '<div class="expansion-branch-telephone">' + workfile.data.branch.telephone + '</div>';
        }
        html +=     '</div>';
        html +=     '<div class="expansion-files" style="gap:1rem;flex-basis:13%">';
        if(workfile.data?.documents){
          workfile.data.documents.forEach(document => {          
            html +=         '<a href="'+ document.link +'" style="font-size: 25px;margin-right:0.5dvw">';
            html +=             '<img src="assets/svg/pdf.svg" style="height:1.5dvw;"/>';
            html +=         '</a>';
          })
        }
        html +=     '</div>';
        html +=     '<div class="expansion-invoices" style="gap:1rem;flex-basis:11%;">';
        if(workfile.data?.invoices){
          workfile.data.invoices.forEach(document => {          
            html +=         '<a href="'+ document.link +'" style="font-size: 25px;margin-right:0.5dvw">';
            html +=             '<img src="assets/svg/factura.svg" style="height:1.5dvw;"/>';
            html +=         '</a>';
          })
        }
        html +=     '</div>';
        html +=   '</div>';
        html += '</div>';
      return html;
    },
    setStatusFilters(field, value) {
      return new Promise((resolve) => {
        if(field == "overrideAll"){
          this.filters = value;
        }else if(field == "reparationTime"){
          this.filters.reparationTime = 1;
          this.filters.outOfStock = 0;
        }else if(field == "outOfStock"){
          this.filters.outOfStock = 1;
          this.filters.reparationTime = 0;
        }else if(field == "all"){
          this.filters.reparationTime = 0;
          this.filters.outOfStock = 0;
        }else{
          this.filters[field] = value;
        }
        resolve();
      });
    }

  }
});

// by now at least
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWorkfilesStore, import.meta.hot))
}
