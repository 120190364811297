<script setup>
import { onMounted, computed, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useSidenavbarStore } from '@/stores/sidenavbar.js';
import { useCommunicatorStore } from '@/stores/communicator.js';
import LocalStorage from '@/controller/LocalStorage.js';
import { isAuthorized } from "@/utils/Authorization.js";
import { Popover } from 'bootstrap';
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useGlobalStore } from '@/stores/global.js';
const globalsStore = useGlobalStore();
const communicatorStore = useCommunicatorStore();
const { getLastBrandingChanges: lastBrandingChanges } = storeToRefs(communicatorStore);
const { t } = useI18n();

const userColor = ref(LocalStorage.getColor());
const userSecondaryColor = ref(LocalStorage.getColorSecondary());
const userTertiaryColor = ref(LocalStorage.getColorTertiary());
const selectedColor = ref(LocalStorage.getColorSelected());
const selectedColorHover = ref(LocalStorage.getColorSelectedHover());
const userLogo = ref(LocalStorage.getUserLogo());

const yautyLogo = '/assets/logos/yauty-logo.svg';

const slider = ref(null);
const menu = ref(null);
const router = useRouter();
const path =  router.currentRoute.value;
const userPermissionsLoaded = ref(false);
const sidenavbarStore = useSidenavbarStore();
const homeIcon = '/assets/home/home.svg';
const orIcon = '/assets/home/reparacion.svg';
const carIcon = '/assets/home/car.svg';
const configIcon = '/assets/home/ajustes.svg';
const doorIcon = '/assets/svg/door.svg';
const adminIcon = '/assets/svg/private.svg';
const isNorthgate = ref(LocalStorage.isNorthgate());
var listItems = [];
const menuItemHeight = ref(0);
const slideHeight = ref(39);
function animateSlider(index){
  let offset, height;
  if(menu.value){
    height = menu.value.offsetHeight - 20;
    offset = (index * menu.value.offsetHeight) + 10;
  }
  if (slider.value) {
    slider.value.style.top = `${offset}px`;
    slider.value.style.height = `${height}px`;
  }
}

const props = defineProps({
  animateToWorkfiles: Number
});

//Estem pendents de canvis de branding que es facin a configurationOrganization
const brandingChanged = computed(() => lastBrandingChanges.value);
const viewChanged = computed(() => globalsStore.getCurrentView);
watch(brandingChanged, (value) => {
  if(value){
    userColor.value = LocalStorage.getColor();
    userSecondaryColor.value = LocalStorage.getColorSecondary();
    userTertiaryColor.value = LocalStorage.getColorTertiary();
    selectedColor.value = LocalStorage.getColorSelected();
    selectedColorHover.value = LocalStorage.getColorSelectedHover();  
    userLogo.value = LocalStorage.getUserLogo(); 
  }
});
watch(viewChanged, (value) => {
  //the only way to detect browser refresh (F5) by now
  if(value){
    switch(value){
      case 'home':
        animateSlider(0);
        break;
      case 'vehicles':
      case 'vehicle':
        animateSlider(1);
        break;
      case 'workfiles':
      case 'workfile':
        animateSlider(2);
        break;
      case 'configuration':
      case 'configurationList':
        animateSlider(3);
        break;
      case 'ifs':
        animateSlider(4);
      break;
    }
  }
});

onMounted(() => {
  if(['home', 'login'].includes(path.name)){
    setTimeout(() => {
      animateSlider(0);
    }, 500);
  }
});
watch(() => props.animateToWorkfiles, (value) => {
  if(value){
    animateSlider(2);
  }
});
</script>

<template>
  <div v-if="!$route.meta.hideNavbar" class="slidebar">
    <div class="sidebar__content d-flex flex-sm-column flex-row align-items-center sticky-top collpase-sidenavbar">
      <div class="yauty-logo-wrapper">
        <router-link
          to="/"
          class="nav-link"
          title=""
          data-bs-toggle="tooltip"
          data-bs-placement="right"
          data-bs-original-title="Home"
        >
          <span>
            <img :src="yautyLogo" style="width:3dvw;height:24px;" class="yautyLogo"/>
          </span>
        </router-link>
      </div>
      <div class="nav nav-pills nav-flush flex-sm-column flex-row mb-auto mx-auto justify-content-between w-100 menu-wrapper">
        <div class="slider" ref="slider"></div>
        <div class="menu-item-wrapper" @click="animateSlider(0)" ref="menu">
            <router-link
              to="/"
              class="route-linker"
              :class="router.currentRoute.value.name === 'home' ? 'active' : ''"
            >
              <div class="sidenav-link">
                <div>
                  <img :src="homeIcon" style="width:1.7dvw;" class="homeIcon"/>
                </div>
                <div class="menu-label">{{ $t('route.home') }}</div>
              </div>
            </router-link>
        </div>
        <div class="menu-item-wrapper" @click="animateSlider(1)">
            <router-link
              to="/vehicles"
              class="route-linker"
              :class="
                router.currentRoute.value.name === 'vehicles' ||
                router.currentRoute.value.name === 'vehicle' ||
                router.currentRoute.value.name === 'vehicle/add' ||
                router.currentRoute.value.name === 'createAppointment'
                  ? 'active'
                  : ''
              "
            >
              <div class="sidenav-link">
                <div>
                  <img :src="carIcon" style="width:2.1dvw;" class="carIcon"/>
                </div>
                <div class="menu-label">{{ $t('route.vehicles') }}</div>
              </div>
            </router-link>
        </div>
        <div class="menu-item-wrapper" @click="animateSlider(2)">
            <router-link
              to="/workfiles"
              class="route-linker"
              :class="
                router.currentRoute.value.name === 'workfiles' ? 'active' : '' ||
                router.currentRoute.value.name === 'workfile' ? 'active' : ''
              "
            >
              <div class="sidenav-link">
                <div>
                  <img :src="orIcon" style="width:1.7dvw;" class="orIcon"/>
                </div>
                <div class="menu-label">{{ $t('route.workfiles') }}</div>
              </div>
            </router-link>
        </div>
        <div v-if="isAuthorized('organizationMenu')" data-bs-trigger="hover" :data-bs-content="t('route.configuration')" :ref="el => { listItems[3] = el }">
          <div class="menu-item-wrapper" @click="animateSlider(3)">
            <router-link
              to="/configuration"
              class="route-linker"
              :class="
                router.currentRoute.value.name === 'configurationList' ||
                router.currentRoute.value.name === 'configuration' ||
                router.currentRoute.value.name === 'createInvitation' ? 'active' : ''
              "
            >
              <div class="sidenav-link">
                <div>
                  <img :src="configIcon" style="width:1.7dvw;" class="confIcon"/>
                </div>
                <div class="menu-label">{{ $t('field.organization') }}</div>
              </div>
            </router-link>
          </div>
        </div>  
        <div v-if="isAuthorized('yautyadmin')" data-bs-trigger="hover" :ref="el => { listItems[4] = el }">
          <div class="menu-item-wrapper" @click="animateSlider(4)">
            <router-link
              to="/yfs"
              class="route-linker"
              :class="
                router.currentRoute.value.name === 'yfs' ? 'active' : ''
              "
            >
              <div class="sidenav-link">
                <div>
                  <img :src="adminIcon" style="width:1.5dvw;" class="adminIcon"/>
                </div>
                <div class="menu-label">Yfs</div>
              </div>
            </router-link>
          </div>
        </div> 
      </div>  
      <div class="user-logo-wrapper">
        <div class="user-logo-inner">
          <img :src="userLogo" :class="{'northgate':isNorthgate}" class="userLogo"/>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.nav-pills{
  position: relative;
}
.slider{
  display:inline-block;
  width:3px;
  height:v-bind(slideHeight);
  border-radius:3px;
  background-color:v-bind(userSecondaryColor);
  position:absolute;
  z-index:1;
  top:10px;
  left:10px;
  transition-duration: 0.4s;
  transition-property: top;
  transition-delay: ease-in;
  @media (min-width: 769px) and (max-width: 1367px) and (orientation: landscape){
    width: 4px !important;
}
}

.slidebar {
  top: 0;
  z-index: 1020;
  background: transparent linear-gradient(180deg, v-bind(userColor) 0%, v-bind(userTertiaryColor) 100%) 0% 0% no-repeat padding-box;
  margin: 0;
  padding: 0;
  position: fixed;
  height: 100dvh;
  overflow: hidden;
  @media (max-width:576px){
    display: none;
  }
  //tablets verticales
  @media (min-width: 577px) and (max-width: 1024px) and (orientation: portrait){
    display: none;
  }
  a {
    text-decoration: none;
    color: white;
  }
  .sidenav-link {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 0px;
    .sidenav-link-text {
      font-weight: 500;
      opacity: 0.6;
      .sidenav-link-text-inside {
        padding: 0 10px;
        align-content: center;
        justify-content: center;
        align-items: center;
        display: flex;
        height: 100%;
      }
    }
    &.active {
      .sidenav-link {
        width: 100%;
        background-color: v-bind(selectedColor);
      }
      .sidenav-link-text {
        opacity: 0.32;
      }
    }
  }
  .collpase-sidenavbar {
    width: 5dvw;
    transition: 0.7s;
  }
  .sidebar__content {
    display: flex;
    flex-direction: column;
    height: 100dvh;
    .menu-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .sidenav-link {
        height: 8dvh;
        color: rgba($color: #ffff, $alpha: 0.5);
        justify-content: center;
        align-items: center;
        display: flex;
        overflow: hidden;
        div{
          display: flex;
          height: 100%;
          width: 5dvw;
          justify-content: center;
          align-items: center;
          transition: 0.3s;
          font-size: 1dvw;
          color: white;
          font-weight: bold;
          @media (min-width: 769px) and (max-width: 1367px) and (orientation: landscape){
            width: 8dvw;
          }
        }
        div:nth-child(2), div:nth-child(3){
          font-size: 0.7dvw;
        }
        &:hover div{
          transform: translateX(-100%);
          color: white;
          background-color: v-bind(selectedColorHover);
        }
      }
    }
  }
}
.slidebar::-webkit-scrollbar {
  display: none;
}
.slidebar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.slide-fade-enter-from {
  opacity: 0;
  display: none;
}
.slide-fade-enter-to {
  opacity: 0;
  display: none;
}
.slide-fade-enter-active {
  transition: 0.5s;
  opacity: 0.6;
}
.slide-fade-leave-from {
  transition: 0.5s;
  display: block;
}
.highlight-decoration{
  width: 0.26dvw;
  height: 5.5dvh;
  background-color: v-bind(userSecondaryColor);
  position: absolute;
  top: 1.3dvh;
  left: 1.3dvh;
  z-index: 1;
  border-radius: 3px;
}
.menu-item-wrapper:hover .highlight-decoration {
  display: none;
}
.yauty-logo-wrapper{
  width: 100%;
  height: 8dvh;
  display: flex;
  align-items: center;
  justify-content: center;  
  .yautyLogo{
    @media (min-width: 769px) and (max-width: 1367px) and (orientation: landscape){
      width: 5.5dvw !important;
      margin-left: 2.8dvw;
    }
  }
}
.user-logo-wrapper{
  flex-grow: 1;
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
  padding-bottom: 3rem;
  .user-logo-inner{
    img{
      max-height: 20dvh;
      object-fit: cover;
    }
    .northgate{
      max-height: 28dvh;
    }
  }
}
.menu-label{
  padding-left: 3px;
  padding-right: 3px;
}

 //tablets horizontales
 @media (min-width: 769px) and (max-width: 1367px) and (orientation: landscape){
 
  .menu-label{
    font-size: 12px !important;
  }
 .homeIcon, .confIcon, .orIcon{
    width: 2.5dvw !important;
 }
 .carIcon{
  width: 2.8dvw !important;
 }
 .adminIcon{
  width: 2.2dvw !important;
 }
 .slidebar {
    width: 7.5dvw;
    .sidebar__content {
      .menu-wrapper {
        margin-top: 15px;
      }
   }
  }
 .sidenav-link{
  justify-content: space-between;
  margin: 8px 0px;
 }
  .user-logo-wrapper{
    padding-left: 35px;
    .user-logo-inner{
      .northgate{
        max-height: 22dvh;
      }
    }
  }
}
</style>

