<script setup>
import { computed, ref, onMounted, onUnmounted, watch  } from "vue";
import { useRouter } from "vue-router";
import { useCommunicatorStore } from '@/stores/communicator.js';
import LocalStorage from '@/controller/LocalStorage.js';
import { isAuthorized } from "@/utils/Authorization.js";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useGlobalStore } from '@/stores/global.js';
import YBtn from '@/components/common/form/YBtn/YBtn.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Notifications from '@/components/common/navbars/components/Notifications.vue';
import GlobalSearcher from "@/components/common/navbars/components/GlobalSearcher.vue";
const props = defineProps({
    animateToWorkfiles: Number
})
const router = useRouter();
const globalsStore = useGlobalStore();
const communicatorStore = useCommunicatorStore();
const { getLastBrandingChanges: lastBrandingChanges } = storeToRefs(communicatorStore);
const path =  router.currentRoute.value;
const homeIcon = '/assets/home/home.svg';
const yautyLogo = '/assets/logos/yauty-logo.svg';
const orIcon = '/assets/home/reparacion.svg';
const carIcon = '/assets/home/car.svg';
const configIcon = '/assets/home/ajustes.svg';
const adminIcon = '/assets/svg/private.svg';
const exit = '/assets/home/exit.svg';
const userColor = ref(LocalStorage.getColor());
const userSecondaryColor = ref(LocalStorage.getColorSecondary());
const userTertiaryColor = ref(LocalStorage.getColorTertiary());
const selectedColor = ref(LocalStorage.getColorSelected());
const selectedColorHover = ref(LocalStorage.getColorSelectedHover());
const { t } = useI18n();
const isHome = computed(() => router.currentRoute.value.path === '/')
const isMenuOpen = ref(false);
const navbarTabletRef = ref(null);
const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
  console.log("Menú alternado:", isMenuOpen.value);
};
const handleClickOutside = (event) => {
  if (navbarTabletRef.value && !navbarTabletRef.value.contains(event.target)) {
    isMenuOpen.value = false;
  }
};
let yautyData = ref("");
let userNick = ref("");
if (localStorage.getItem('yautyData')) {
    yautyData = ref(JSON.parse(localStorage.getItem('yautyData')));
    userNick = yautyData.value.name.charAt(0).toUpperCase();
}
const isWorkfileRoute = computed(() => {
  return router.currentRoute.value.path.startsWith('/workfile/');
});
const navbarStyle = {};
const menu = ref(null);
const slider = ref(null);
function animateSlider(index){
  const item = menu.value.children[index]; 
  const itemWidth = item.offsetWidth;
  const newPosition = index * itemWidth;

  if (slider.value) {
    slider.value.style.transform = `translateX(${newPosition}px)`;
    slider.value.style.width = `${itemWidth}px`; 
  }
}

const isHidden = ref(false);
let lastScrollTop = 0;
const handleScroll = (event) => {
  const scrollTop = event.target.scrollTop || 0;

  if (scrollTop > lastScrollTop){
    isHidden.value = true;
  }else{
    isHidden.value = false;
  }

  lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
};

const brandingChanged = computed(() => lastBrandingChanges.value);
const viewChanged = computed(() => globalsStore.getCurrentView);

watch(brandingChanged, (value) => {
  if(value){
    userColor.value = LocalStorage.getColor();
    userSecondaryColor.value = LocalStorage.getColorSecondary();
    userTertiaryColor.value = LocalStorage.getColorTertiary();
    selectedColor.value = LocalStorage.getColorSelected();
    selectedColorHover.value = LocalStorage.getColorSelectedHover();  
    // userLogo.value = LocalStorage.getUserLogo(); 
  }
});
watch(viewChanged, (value) => {
  //the only way to detect browser refresh (F5) by now
  if(value){
    switch(value){
      case 'user':
        break;
      case 'home':
        animateSlider(0);
        break;
      case 'vehicles':
      case 'vehicle':
        animateSlider(1);
        break;
      case 'workfiles':
      case 'workfile':
        animateSlider(2);
        break;
      case 'configuration':
      case 'configurationList':
        animateSlider(3);
        break;
      case 'ifs':
        animateSlider(4);
      break;
      case 'logout':
      break;
    }
  }
});
watch(() => props.animateToWorkfiles, (value) => {
  if(value){
    animateSlider(2);
  }
});

onMounted(() => {
  if(['home', 'login'].includes(path.name)){
    setTimeout(() => {
      animateSlider(0);
    }, 500);
  }
  window.addEventListener('scroll', handleScroll);

  const appContainer = document.getElementById('app');
  if (appContainer){
    appContainer.addEventListener('scroll', handleScroll);
  }
});
onUnmounted(() => {
//   window.removeEventListener('resize', updateView);
  window.removeEventListener('scroll', handleScroll);
  const appContainer = document.getElementById('app');
  if (appContainer){
  }
});

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleClickOutside);
});

var listItems = [];
</script>

<template>
  <nav
    v-if="!$route.meta.hideNavbar" 
    class="navbar" :style="navbarStyle" :class="[{'hidden' : isHidden}, isHome ? 'navbar-home' : 'navbar-default', isWorkfileRoute ? 'workfile-nav' : 'navbar-default']"
    ref="navbarTabletRef"
    >
    <div class="navbar__brand yauty-logo">
        <router-link
            to="/"
            class="nav-link"
            title=""
            data-bs-toggle="tooltip"
            data-bs-placement="right"
            data-bs-original-title="Home"
        >
            <span>
            <img :style="yautyLogoStyle" :src="yautyLogo" style="width:22dvw;height:auto"/>
            </span>
        </router-link>
    </div>
    <div class="menu nav-item">
      <div class="nav-item d-flex notifications-item">
            <Notifications />
        </div>
        <div class="nav-item dropdown">
            <YBtn
                class="d-flex nav-link"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :rounded="true"
                roundedSize="full"
                :noPadding="true"
            >
            <div class="branding__link">{{ userNick }}</div>
                </YBtn>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <router-link to="/user" class="dropdown-item">{{
                $t("user.profile")
            }}</router-link>
            <div class="dropdown-divider"></div>
            <router-link to="/logout" class="dropdown-item">{{
                $t("logout.logout")
            }}</router-link>
            </div>
        </div>
    </div>
    <ul class="top-navbar-vertical-tablet" ref="menu">
        <div class="hSlider" ref="slider"></div>
        <li @click="animateSlider(0)" >
            <router-link
            to="/"
            class="route-linker"
            :class="router.currentRoute.value.name === 'home' ? 'active' : ''"
        >
            <div class="sidenav-item">
            <div class="sidenav-link">
                <div class="item-icon">
                <img class="navIcons" :src="homeIcon" style="width:3.5dvw;"/>
                </div>
                <div class="menu-label">{{ $t('route.home') }}</div>
            </div>
            </div>
            </router-link>
        </li>
        <li @click="animateSlider(1)">
            <router-link
                to="/vehicles"
                class="route-linker"
                :class="
                    router.currentRoute.value.name === 'vehicles' ||
                    router.currentRoute.value.name === 'vehicle' ||
                    router.currentRoute.value.name === 'vehicle/add' ||
                    router.currentRoute.value.name === 'createAppointment'
                    ? 'active'
                    : '' ">
                <div class="sidenav-item">
                <div class="sidenav-link">
                    <div class="item-icon">
                    <img  :src="carIcon" style="width:4.5dvw;"/>
                    </div>
                    <div class="menu-label">{{ $t('route.vehicles') }}</div>
                </div>
                </div>
            </router-link>
        </li>
        <li @click="animateSlider(2)">
            <router-link
                to="/workfiles"
                class="route-linker"
                :class="
                    router.currentRoute.value.name === 'workfiles' ? 'active' : '' ||
                    router.currentRoute.value.name === 'workfile' ? 'active' : ''
                "
                >
                <div class="sidenav-item">
                <div class="sidenav-link">
                    <div class="item-icon">
                    <img class="navIcons" :src="orIcon" style="width:4dvw;"/>
                    </div>
                    <div class="menu-label">{{ $t('route.workfiles') }}</div>
                </div>
                </div>
            </router-link>
        </li>
        <div v-if="isAuthorized('organizationMenu')" data-bs-trigger="hover" :data-bs-content="t('route.configuration')" :ref="el => { listItems[3] = el }">
            <li @click="animateSlider(3)">
                <router-link
                    to="/configuration"
                    class="route-linker"
                    :class="
                    router.currentRoute.value.name === 'configurationList' ||
                    router.currentRoute.value.name === 'configuration' ||
                    router.currentRoute.value.name === 'createInvitation' ? 'active' : ''
                    "
                >
                <div class="sidenav-item">
                    <div class="sidenav-link">
                    <div class="item-icon">
                        <img class="navIcons" :src="configIcon" style="width:4dvw;"/>
                    </div>
                    <div class="menu-label">{{ $t('route.configuration') }}</div>
                    </div>
                </div>
                </router-link>
            </li>
        </div>
        <div v-if="isAuthorized('yautyadmin')" data-bs-trigger="hover" :ref="el => { listItems[4] = el }">
            <li @click="animateSlider(4)">
                <router-link
                    to="/yfs"
                    class="route-linker"
                    :class="
                    router.currentRoute.value.name === 'yfs' ? 'active' : ''
                    ">
                    <div class="sidenav-item">
                    <div class="sidenav-link yfs"  >
                        <div class="item-icon">
                        <img class="navIcons" :src="adminIcon" style="width:3.2dvw;"/>
                        </div>
                        <div class="menu-label">yfs</div>
                    </div>
                    </div>
                </router-link>
            </li>
        </div>    
    </ul> 
    
    <div class="navbar__toggle-tablet" >
        <font-awesome-icon icon="chevron-down" class="chevron-up" v-show="isMenuOpen"  @click="toggleMenu"/>
        <font-awesome-icon icon="chevron-down" class="chevron-down" v-show="!isMenuOpen"  @click="toggleMenu"/>
    </div>
    <ul :class="{'navbar__menu': true, 'navbar__menu--open': isMenuOpen}" >
        <div v-if="isMenuOpen" class="vertical-tablet" >
            <!-- <div class="search" style="flex-grow: 1;">
                <GlobalSearcher />
            </div> -->
            <div class="menus d-flex">
                <div class="nav-item d-flex notifications-item">
                    <Notifications />
                </div>
                <div class="nav-item dropdown">
                    <YBtn
                        class="d-flex nav-link"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        :rounded="true"
                        roundedSize="full"
                        :noPadding="true"
                        >
                    <div class="branding__link">{{ userNick }}</div>
                    </YBtn>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <router-link to="/user" class="dropdown-item">
                            {{ $t("user.profile")}}
                        </router-link>
                        <div class="dropdown-divider"></div>
                        <router-link to="/logout" class="dropdown-item">
                            {{ $t("logout.logout") }}
                        </router-link>
                    </div>
                </div>
            </div>
          </div>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.top-navbar-vertical-tablet{
  display: none;
}
.vertical-tablet{
  display: none;
}
.navbar {
  * {
    font-family: 'CircularStd-Book';
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #515151;
  border-bottom: 1px solid #f5f5f5;
  height: 61px;
  position: relative;
  &__toggle-tablet{
      display: none;
    }
  &__toggle{
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
    z-index: 6;
  }
  &__menu{
    overflow: hidden;
    display: none;
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    @media (max-width: 576px) {
      max-height: 0;
      overflow: hidden;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      transition: max-height 0.3s ease-in-out;
      margin-top: 52px;
      position: absolute;
      right: 0;
      border-radius: 0px 0px 12px 12px;
      &--open{
        z-index: 6;
        max-height: 100dvh;
        margin-top: 52px;
        border-radius: 0px 0px 12px 12px;
      }
    }
  }
  &__item{
    margin-left: 1rem;
  }
  .search{
    margin-left: 16px;
  }
  a {
    display: block;
    width: 100%;
    color: #515151;
    cursor: pointer;
    text-decoration: none;
    font-weight: 600;
    &:nth-child(3) {
      border-bottom-left-radius: calc(0.375rem - 1px);
      border-bottom-right-radius: calc(0.375rem - 1px);
    }
    &:nth-child(1) {
      border-top-left-radius: calc(0.375rem - 1px);
      border-top-right-radius: calc(0.375rem - 1px);
    }
    &:hover {
      color: #0195d8;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .yauty-logo{
    padding-left: 25px;
    margin-top: 10px;
    visibility: hidden;
    @media (max-width: 576px) {
      visibility: visible;
    }
  }
  .dropdown-menu[data-bs-popper] {
    left: -5.7rem;
    top: 5dvh;
  }
  .dropdown-menu {
    --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1rem;
  --bs-dropdown-color: #212529;
  --bs-dropdown-bg: #fff;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 0.375rem;
  --bs-dropdown-border-width: 1px;
  --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: #212529;
  --bs-dropdown-link-hover-color: #1e2125;
  --bs-dropdown-link-hover-bg: #e9ecef;
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #0d6efd;
  --bs-dropdown-link-disabled-color: #adb5bd;
    --bs-dropdown-item-padding-x: 0.9rem;
    --bs-dropdown-item-padding-y: 0.9rem;
    --bs-dropdown-header-color: #6c757d;
    --bs-dropdown-header-padding-x: 1rem;
    --bs-dropdown-header-padding-y: 1rem;
    position: absolute;
    z-index: var(--bs-dropdown-zindex);
    min-width: var(--bs-dropdown-min-width);
    padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
    margin: 0;
    font-size: var(--bs-dropdown-font-size);
    color: var(--bs-dropdown-color);
    text-align: left;
    list-style: none;
    background-color: var(--bs-dropdown-bg);
    background-clip: padding-box;
    border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
    border-radius: var(--bs-dropdown-border-radius);
  }
  .branding__link {
    color: white;
    background-color: v-bind(userColor);
    height: 32px;
    width: 32px;
    text-align: center;
    border-radius: 30px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.route-linker {
  &.active {
    .item-icon {
    //border-left: 4px solid v-bind(userSecondaryColor);
    padding-left: 10px;
    margin-left: 20px;
    }
    .sidenav-link{
      margin-left: 0px;
      background-color: v-bind(selectedColor);
    }
}
}

.sidenav-link{
  display: flex;
  width: 100dvw;
  gap: 22px;
  margin-left:30px;
  padding-left: 10px;
  padding-bottom: 20px;
  padding-top: 20px;
  position: relative;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.sidenav-item{
  border-bottom: 0.1px solid rgba(194, 194, 194, 0.3);
  margin-left: -15px;
}
.selected .sidenav-item{
  background-color: v-bind(selectedColor); 
}
.menu-label{
  padding: 5px 0px;
}
.menu-label.car{
  margin-left: -3px;
}
.menu-label.yfs{
  margin-left: 5px;
}
.menu-label.exit{
  margin-left: 2.5px;
}

.menu{
  position: absolute;
  top: 1dvh;
  right:0;
  display: flex;
  flex: 3;
  gap: 20px;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-right: 1.2dvw;
  @media (max-width:576px) {
    display: none;
  }
  .nav-item{
    padding: 0 10px;
  }
}

//tablets verticales
@media (min-width: 577px) and (max-width: 1024px) and (orientation: portrait){
    .selected .sidenav-item{
    background-color: transparent; 
  }
  .top-navbar-vertical-tablet{
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100dvw;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0px;
  .hSlider{
  width: 6.8dvw !important;
  display:flex;
  flex-direction: row;
  justify-content: center;
  height:3px;
  border-radius:3px;
  background-color:v-bind(userSecondaryColor);
  position:relative;
  z-index:1;
  top:3dvh !important;
  left:8dvw;
  transition: transform 0.3s ease-in-out;
}
  li{
    list-style: none;
  }
    .sidenav-item{
    border-bottom: none;
    margin-left: 0px;
    padding-left: 0px;
    height: 8dvh;
    width: auto;
      .sidenav-link{
      background-color: transparent;
      padding: 0px !important;
      margin-left: 0px;
      margin-right: 0px;
      width: 9.5dvw;
      height: 100%;
      gap: 0px !important;
      justify-content: center;
        .menu-label{
          display: none;
        }
      }
    } 
    .route-linker {
      padding: 0px;
        &.active {
          background-color: v-bind(selectedColor);
          .item-icon {
          border-left: none;
          margin-left: 0px;
          padding-left: 0px;
          padding-right: 0px;
          }
      }
    }
  }
  .vertical-tablet{
    display: flex;
    width: 100dvw;
    justify-content: flex-end;
    align-items: center;
    padding-right: 25px !important;
    height: 8dvh;
    li{
      list-style: none;
    }
  }
  .phone-item{
    display: none;
  }
  ul.navbar__menu {
    overflow: visible; 
    position: relative; 
}

  .navbar{
    padding: 0px;
    border-bottom: none;
    background: transparent linear-gradient(270deg, v-bind(userColor) 0%, v-bind(userTertiaryColor) 100%) 0% 0% no-repeat padding-box;
    height: 8dvh;
    position: fixed;
    width: 100dvw;
    transition: transform 0.18s ease-in-out;
    z-index: 1000;
    overflow: visible;
    &.hidden {
      transform: translateY(-100%);
    }
    &__toggle{
      display: none;
    }
    &__toggle-tablet{
      display: block;
      position: absolute;
      z-index: 1000;
      max-width: 30px;
      margin-left: 16dvw;
      margin-top: 1.5dvh;
      .chevron-down{
        font-size: 16px;
        color: white;
      }
      .chevron-up{
        font-size: 16px;
        color: white;
        transform: rotate(180deg);
      }
      .icon{
        height: 1.2em;
        margin-left: 100px;
        color: white;
      }
    }
    .icon-user{
        height: 1.7em !important;
        color: white;
      }

      &__menu{
        max-height: 0;
        overflow: hidden;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        transition: max-height 0.3s ease-in-out;
        background-color: white;
        background-image: none;
        margin-top: 50dvh;
        position: absolute;
        top: -48.5dvh;
        right: 0;
        border-radius: 0px 0px 0px 0px;
        &--open{
          z-index: 6;
          max-height:800px;
          margin-top: 50.7dvh;
          border-radius: 0px 0px 0px 0px;
        }
        
      }
  }

  .menu{
    display: none;
  }
  .yauty-logo{
    margin-top: 2.9dvh !important;
    visibility: visible !important;
    z-index: 999;
    img{
      width: 12dvw !important ;
    }
  }
  .navbar__item.d-flex.notifications-item {
    position: relative;
    z-index: 1001; 
}
}

@media (min-width:577px) and (max-width: 686px){
    
    .top-navbar-vertical-tablet{
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100dvw;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0px;
    .hSlider{
    width: 6.4dvw !important;
    display:flex;
    flex-direction: row;
    justify-content: center;
    height:3px;
    border-radius:3px;
    background-color:v-bind(userSecondaryColor);
    position:relative;
    z-index:1;
    top:3dvh !important;
    left:7.8dvw;
    transition: transform 0.3s ease-in-out;
  }
}
  .navbar{
    &__toggle-tablet{
      display: block;
      position: absolute;
      z-index: 1000;
      max-width: 30px;
      margin-left: 17dvw;
      margin-top: 1.5dvh;
      .chevron-down{
        font-size: 16px;
        color: white;
      }
      .chevron-up{
        font-size: 16px;
        color: white;
        transform: rotate(180deg);
      }
      .icon{
        height: 1.2em;
        margin-left: 100px;
        color: white;
      }
    }
  }
}
</style>

