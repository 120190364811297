<script setup>
import { ref, computed } from "vue";
import { RouterView, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

import { useSidenavbarStore } from "@/stores/sidenavbar.js";

const { t } = useI18n();
const sidenavbarStore = useSidenavbarStore();
const router = useRouter();

const pathName =  router.currentRoute.value.name;
const isLogin = computed(() => router.currentRoute.value.path === '/login');
const isRecover = computed(() => router.currentRoute.path === 'recover-account');
const wrapperRef = ref(null);
const loginStyle = computed(() => {
  if(isLogin.value || isRecover){
    return{
      paddingLeft : '0dvw'
    }
  }
})

let yautyData = ref('');
if (localStorage.getItem('yautyData')) {
  yautyData = JSON.parse(localStorage.getItem('yautyData'));
}
const emit = defineEmits(["animateSidebar"]);
function animateSidebar() {
  emit("animateSidebar");
}

</script>

<template>
  <div
    ref="wrapperRef"
    :style="loginStyle"
    class="app__content"
    v-bind:class="[(yautyData.name && pathName != 'invitations' && pathName != 'activate') ? 'wrapper' : 'no-wrapper']"
    :class="[sidenavbarStore.isCollapse ? '' : 'extended-wrapper']"
    :key="$route.fullPath"
    @callback="handleCallback"
  >
    <RouterView :key="$route.fullPath" @animateSidebar="animateSidebar"/>
  </div>
  <div class="orientation-modal">
      <div class="orientation-content">
        <p>{{ $t('orientation.modal') }}</p>
      </div>
    </div>
</template>

<style lang="scss">
  @import 'src/styles/colors.scss';
.orientation-modal{
  display: none;
}
.wrapper {
  padding-left: 5dvw !important;
  min-height: 91.45dvh;
  transition: 0.7s;
  background-color: $layout-background;
  @media (min-width: 769px) and (max-width: 1367px) and (orientation: landscape) {
    padding-left: 6dvw;
  }
}

.no-wrapper {
  min-height: calc(100vh - 60px);
}

.extended-wrapper {
  padding-left: 210px;
  transition: 0.7s;
}

//moviles
@media (max-width:576px){
  .wrapper {
    display: flex;
    flex-direction: column;
  }
  .app__content, .wrapper{
    padding-left: 0 !important;
    min-height: 95.5dvh !important;
  }
  .no-wrapper {
      min-height: 100% !important;
    }
  }

  @media (min-width: 577px) and (max-width: 1024px) and (orientation: portrait){
    .app__content, .wrapper{
    padding-left: 0 !important;
  }
  .no-wrapper {
      min-height: 100% !important;
    }
}
</style>
