import { defineStore, acceptHMRUpdate } from 'pinia';
import { url } from '@/conf/conf.js';
import * as Comlink from 'comlink';
import {} from '@/workers/workerApi';
import { middleware } from "@/services/api/Middleware.js";
import { read } from 'xlsx';
export const useNotificationStore = defineStore({
    id: "notification",
    state: () => ({
        ids: [],
        notifications: [],
        notificationsUnread: [],
        notificationsCount: 0,
        notificationsTotalCount: 0,
        loadingNotifications: false,
        listPage: 1,
        maxPages: 0,
        offset: 0,
        orderBy: 'created',
        order: 'desc',
        page: 1,
        filters:{
            type:'',
            read: '',
            data: '',
            createdStartDate: '',
            createdEndDate: ''
        }
    }),
    getters: {
        getNotifications(state) {
            return state.notifications;
        },
        getNotificationsUnread(state) {
            return state.notificationsUnread;
        },
        getNotificationsCount(state) {
            return state.notificationsCount;
        },   
        getNotificationsTotalCount(state) {
            return state.notificationsTotalCount;
        },
        isLoading(state) {
            return state.loadingNotifications;
        },
        getNotificationslistPage(state) {
            return state.listPage;
        },          
    },
    actions: {
        async fetchNotifications(params) {
            try {
                this.loadingNotifications = true;
                return await fetch(url, {
                    method: "POST",
                    headers: {
                        'Accept': "application/json",
                        'Content-Type': "application/json",
                    },
                    body: JSON.stringify({
                        validation_key: params.k,
                        method: "getNotifications",
                        // limit: params.limit,
                        // orderBy: params.orderBy ? params.orderBy : state.orderBy,
                        // order: params.order ? params.order : state.order,
                        // offset: params.offset ? params.offset : state.offset,
                        // read: params.read ? params.read : state.filters.read,
                        // type: params.type ? params.type : state.filters.type,
                        // data: params.data ? params.data : state.filters.data,
                        // createdStartDate: params.createdStartDate ? params.createdStartDate : state.filters.createdStartDate,
                        // createdEndDate: params.createdEndDate ? params.createdEndDate : state.filters.createdEndDate,
                        limit: 5,
                        orderBy: "created",
                        order: "desc",
                        read: 0,
                        offset: 0
                    }),
                })
                .then((response) => response.json())
                .then((data) => {
                    console.log('succes store', data)
                    this.loadingNotifications = false;
                    this.notifications = [];
                    this.notificationsUnread = [];
                    this.ids = [];
                    this.notificationsTotalCount = 0;
                    if(data.notifications.length === 0) {
                        return [];
                    }else{
                        this.notificationsTotalCount = data.count;
                        var that = this;
                        data.notifications.forEach(notif => {
                            let notificationData = Object.assign({}, notif);
                            notificationData.or     = notif.data && notif.data.orNumber ? notif.data.orNumber : '';
                            notificationData.brand = notif.data && notif.data.vehicle ? notif.data.vehicle.brand : '';
                            notificationData.model = notif.data && notif.data.vehicle ? notif.data.vehicle.model : '';
                            notificationData.plate = notif.data && notif.data.vehicle ? notif.data.vehicle.plate : '';
                            notificationData.vin = notif.data && notif.data.vehicle ? notif.data.vehicle.vin : '';
                            notificationData.workfileId = notif.data && notif.data.workfileId ? notif.data.workfileId : '';
                            notificationData.vehicleId = notif.data && notif.data.vehicleId ? notif.data.vehicleId : '';
                            that.ids.push(notif.id);
                            that.notifications.push(notificationData);
                            if(notif.read == 0) {
                                that.notificationsUnread.push(notificationData);
                            }
                        });
                        this.listPage = params.offset + 1;
                        return this.notifications;
                    }
                })
                .catch((error) => {
                    console.log('catch store', error)
                    this.loadingNotifications = false;
                });
            } catch (error) {
            }
        },
        async fetchNotificationsCount(params) {
            let options = {
                method: "POST",
                headers: {
                    'Accept': "application/json",
                    'Content-Type': "application/json",
                },
                body: JSON.stringify({
                    validation_key: params.k,
                    method: "notificationsCount",
                }),
            };
            try {
                return middleware(url, options, ['isTokenAlive'])()
                .then((response) => response.json())
                .then((data) => {
                    this.notificationsCount = data.count;
                    return data.count;
                })
                .catch((error) => {
                });
            } catch (error) {
            }
        },
        async notificationsRead(params) {
            try {
                return await fetch(url, {
                    method: "POST",
                    headers: {
                        'Accept': "application/json",
                        'Content-Type': "application/json",
                    },
                    body: JSON.stringify({
                        validation_key: params.k,
                        notifications: params.notifications,
                        method: "notificationsRead",
                    }),
                })
                .then((response) => response.json())
                .then((data) => {
                    return data;
                })
                .catch((error) => {
                    console.error('Error:', error);
                });       
            } catch (error) {
                console.log('error', error);
            }
        },
        async notificationsReadAll(params) {
            try {
                return await fetch(url, {
                    method: "POST",
                    headers: {
                        'Accept': "application/json",
                        'Content-Type': "application/json",
                    },
                    body: JSON.stringify({
                        validation_key: params.k,
                        notifications: this.ids,
                        method: "notificationsRead",
                    }),
                })
                .then((response) => response.json())
                .then((data) => {
                    return data;
                })
                .catch((error) => {
                    console.error('Error:', error);
                });       
            } catch (error) {
                console.log('error', error);
            }
        },
        async fetchAll(params) {
            try {
                let count = this.fetchNotificationsCount({k: params.k});
                let notif = this.fetchNotifications({k: params.k});
                return Promise.all([count, notif])
                        .then((response) => response.json())
                        .then((data) => {
                            return data;
                        })
                        .catch((error) => {});
                     } catch (error) {
                console.log('error', error);
            }
        },
        updateNotificationsData(data) {
            this.notificationsCount = data.notificationsCount.count;
            this.ids = [];
            this.notifications = [];
            this.notificationsUnread = [];
            this.notificationsTotalCount = 0;
            if(data.notificationsList.notifications == undefined || data.notificationsList.notifications.length === 0) {
                return [];
            }else{
                var that = this;
                data.notificationsList.notifications.forEach(notif => {
                    let notificationData = Object.assign({}, notif);
                    notificationData.or     = notif.data && notif.data.orNumber ? notif.data.orNumber : '';
                    notificationData.brand = notif.data && notif.data.vehicle ? notif.data.vehicle.brand : '';
                    notificationData.model = notif.data && notif.data.vehicle ? notif.data.vehicle.model : '';
                    notificationData.plate = notif.data && notif.data.vehicle ? notif.data.vehicle.plate : '';
                    notificationData.vin = notif.data && notif.data.vehicle ? notif.data.vehicle.vin : '';
                    notificationData.workfileId = notif.workfileId;
                    notificationData.vehicleId = notif.vehicleId;
                    that.ids.push(notif.id);
                    that.notifications.push(notificationData);
                    that.notificationsTotalCount++
                    if(notif.read == 0) {
                        that.notificationsUnread.push(notificationData);
                    }
                });
            }
        },
        setStatusFilters(field, value) {
            return new Promise((resolve) => {
              if(field == "created"){
                this.filters.createdStartDate = value.startDate;
                this.filters.createdEndDate = value.endDate;
              }
              else if(['type', 'read', 'data'].includes(field)){
                this.filters[field] = value;
              }
              resolve();
            });
          }
    }
});


if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot))
}