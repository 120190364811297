<script setup>
import { ref, onMounted, onBeforeMount, watch, computed, watchEffect } from "vue";
import Navbar from "@/components/common/navbars/Navbar.vue";
import SideNavbar from "@/components/common/navbars/SideNavbar.vue";
import Layout from "@/components/layouts/Layout.vue";
import UserPermissionMain from "@/components/userPermission/UserPermissionMain.vue";
import SessionExpiredModal from "@/components/common/modals/SessionExpiredModal.vue";
import LocalStorage from '@/controller/LocalStorage.js';
import { useGlobalStore } from '@/stores/global.js';
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useCommunicatorStore } from '@/stores/communicator.js';
import { useRouter } from "vue-router";
import Footer from "@/components/common/footer/Footer.vue";
const communicatorStore = useCommunicatorStore();
const globalStore = useGlobalStore();
const global = storeToRefs(globalStore);
const { getSideNavKey: sideNavKey} = storeToRefs(global);
const { t } = useI18n();
const sideBarKey = computed(() => sideNavKey.value);
const router = useRouter();
const userKey = ref(LocalStorage.getKey());
const showNotifications = ref(false);
const animateSidebarLine = ref(0);
const showModal = ref(false);
function closeModal() {
  router.push("/logout");
}
onBeforeMount(() => {
  window.addEventListener('resize', function() {
    globalStore.resetSideNavKey();
  });
});
function hideNotifications() {
  showNotifications.value = false;
}
function notificationsShown() {
  showNotifications.value = true;
}
function animateSidebar() {
  animateSidebarLine.value = Math.random(0,1);
}
const showExpirationModal = computed(() => {
  return communicatorStore.sessionHasExpired;
});
const disallowedRoutes = ['login', 'logout', 'register', 'invitation', 'activate', 'recoverAccount', 'recoverPassword'];
watchEffect(() => {
  showModal.value = !disallowedRoutes.includes(router.currentRoute.value.name) && showExpirationModal.value;
});
</script>

<template>
  <Navbar 
    :key="sideBarKey"
    :showNotifications="showNotifications" 
    :animateToWorkfiles="animateSidebarLine"
    @notificationsShown="notificationsShown()" 
    @notificationsHidden="hideNotifications()"
  />
  <SideNavbar :key="sideBarKey" :animateToWorkfiles="animateSidebarLine"/>
  <Layout 
    :key="$route.fullPath" 
    @click="hideNotifications()" 
    @animateSidebar="animateSidebar"
  />
  <Footer />
  <UserPermissionMain  />
  <SessionExpiredModal :show="showModal" @closeModal="closeModal"/>
</template>

<style>
@import "@/assets/base.css";
.checkOut {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}
</style>
