<script setup>
import { watch } from "vue";
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const props = defineProps({
  branch: Object
});

watch(() => props.branch, () => {

});

</script>

<template>
    <div class="workfile-branch">
        <div class="workfile-branch-title">
            <span class="subtitle">{{ t('field.branch') }}</span>
        </div>
        <div class="workfile-branch-content">
            <div class="workfile-branch-content-item name" v-if="props.branch">
                <span>{{ props.branch.name }}</span>
            </div>
            <div class="workfile-branch-content-item" v-if="props.branch">
                <span>{{ props.branch.address }}</span>
            </div>
            <div class="workfile-branch-content-item" v-if="props.branch">
                <span>{{ props.branch.city }}</span>
            </div>
            <div class="workfile-branch-content-item" v-if="props.branch">
                <span>{{ props.branch.state  + ' ' + props.branch.postal_code }}</span>
            </div>
            <div class="workfile-branch-content-item" v-if="props.branch">
                <span>{{ props.branch.phone }}</span>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.workfile-branch{
  @media only screen and (max-width: 1279px) {
    padding-left: 1.5rem;
    margin-bottom: 0.5rem;
  }
  .subtitle{
    font-family: 'CircularStd-Bold';
    color: #153440;
    opacity: 0.42;
    font-size: 14px;
  }
  .workfile-branch-content{
    font-size: 12px;
    color: #000000;
  }
  .workfile-branch-content-item{
    // font-family: 'CircularStd-Book';
    // font-size: 14px;
    font: normal normal normal 14px/19px 'CircularStd-Book';
    letter-spacing: 0px;
    color: #000000;
  }
  .bold{
    font-weight: bold;
  }
  .name{
    font-family: 'CircularStd-Bold';
    font-size: 14px;
  }
}
@media (max-width:576px) {
    .workfile-branch-content{
      margin-top: 40px;
      .workfile-branch-content-item.name{
      font-size: 18px !important;
      }
    }
    .workfile-branch-title {
       display: none;
      }
      .workfile-branch-content-item {
        font-size: 15px !important;
        font-family: 'CircularStd-Book';
        line-height: 1.3 !important;
      }
  }

  @media (min-width: 577px) and (max-width: 1024px) and (orientation: portrait) {
    .workfile-branch-title {
      span.subtitle {
      font-family: 'CircularStd-Bold';
      font-size: 21px; 
    }
  }
  .workfile-branch-content {
    .workfile-branch-content-item.name{
      font-family: 'CircularStd-Bold';
    }
    .workfile-branch-content-item {
      font-size: 18px;
      font-family: 'CircularStd-Book';
    }
  }
}
@media (min-width: 769px) and (max-width: 1367px) and (orientation: landscape) {
    .workfile-branch {
      margin-top: 30px !important;
  }
    .workfile-branch-title {
      span.subtitle {
      font-family: 'CircularStd-Bold';
      font-size: 21px; 
    }
  }
  .workfile-branch-content {
    .workfile-branch-content-item.name{
      font-family: 'CircularStd-Bold';
    }
    .workfile-branch-content-item {
      font-size: 18px;
      font-family: 'CircularStd-Book';
    }
  }
}
</style>