<script setup>
import { watch, ref, onMounted } from "vue";

const props = defineProps({
  time: [String, Number],
  statusLog: Object,
  type: [String, Number],
  isExpanded: Boolean
});
const emit = defineEmits(['toggle-expand']);
const toggleExpand = () => {
      emit('toggle-expand');
    };
const color = ref(props.statusLog[0]?.statusColor);
const overTwentyFour = ref(false);
onMounted(() => {
  color.value = props.statusLog[0]?.statusColor;
  if (parseInt(props.time)/60 > 24) {
    overTwentyFour.value = true;
  }
});
</script>

<template>
    <div class="workfile-reparation-time" :style="{'background-color': color}" @click="toggleExpand" :class="{ expanded: isExpanded }">
      <img src="/assets/workfiles/crono_blanc.svg" :class="{ expanded: isExpanded }"/>
      <div class="subtitle" :class="{ 'show': isExpanded }">
        <span class="cell-status-category-primary subtitle" >{{ $t("workfiles.state.primary." + props.statusLog[0]?.status) }}</span>
      </div>
      <div class="over-twenty-four" v-if="overTwentyFour">+24</div>
    </div>
</template>

<style lang="scss">
.workfile-reparation-time{
  white-space: nowrap;
  display: inline-block;
  position: relative;
  min-width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-items: start;
  border-bottom-left-radius: 19px;
  border-bottom-right-radius: 19px;
  padding-left: 1rem;
  gap: 1rem;
  @media only screen and (min-width: 769px) {
    margin-left: 3rem;
  }  
  @media only screen and (min-width: 1367px) {
    margin-left: 3rem;
  }
  @media only screen and (min-width: 1680px) {
    margin-right: 3rem;
  }
  img{
    width: 24px;
    height: 24px;
  }
  .over-twenty-four{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 23px;
    border-radius: 15px;
    background-color: #FF0080;
    color: white;
    position: absolute;
    right: -5px;
    top: -10px;
    font-family: 'CircularStd-Bold';
    font-size: 14px;
  }
  .subtitle{
    width: 100%;
    color: white;
    font-size:18px;
  }
}
@media (min-width: 577px) and (max-width: 1024px) and (orientation: portrait){
  .workfile-reparation-time{
    float: right;
    min-width: 68px;
    justify-content: center;
    padding: 15px 0 15px 15px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-left: 0rem;
    &.expanded{
      min-width: 183.19px;
    }
    img.expanded{
        transition: transform 0.3s ease; 
        &.expanded {
          transform: translateX(-10px);
        }
    }
    .subtitle{
    opacity: 1;
    width: 100%;
    color: white !important;
    font-size:18px;
    transition: opacity 0.3s ease,  width 0.3s ease;
    width: 0;
    overflow: hidden;
    }
    .subtitle.show{
        opacity: 1 !important;
        width: auto;
        transform: translateX(-10px);
    }
    .over-twenty-four {
      top: 0px;
      right: -15px;
    }
  }
}
//Tablets en modo horizontal (de 769px a 1367px)
@media (min-width: 769px) and (max-width: 1367px) and (orientation: landscape) {
  .workfile-reparation-time{
    float: right;
    width: 185px;
    justify-content: center;
    padding: 15px 0 15px 15px;
    margin-right: 25px !important;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-right: -25px;
    padding-left: 30px;
      .over-twenty-four{
      top: 0px;
      }
    }
  }
</style>