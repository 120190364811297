import { ref } from "vue";
import { defineStore, acceptHMRUpdate } from 'pinia';
import { middleware } from "@/services/api/Middleware.js";
import { url } from '@/conf/conf.js';

export const useCommunicatorStore = defineStore({
    id: "communicator",
	state: () => ({ 
        localStorage: new Date().getTime(),
        brandingLocalStorage: new Date().getTime(),
        sessionExpired: false,
        selectdedUser: null,
        showList: false,
        globalSearchItems: {}
    }),
    getters: {
        localStorageLastChange(state) {
            return state.localStorage;
        },
        getLastBrandingChanges(state) {
            return state.brandingLocalStorage;
        },
        sessionHasExpired(state) {
            return state.sessionExpired;
        },
        getSelectedUser(state) {
            return state.selectdedUser;
        },
        getShowList(state) {
            return state.showList;
        },
        getGlobalSearchItems(state) {
            return state.globalSearchItems;
        }
    },
    actions: {
        localStorageChanged() {
            this.localStorage = new Date().getTime();
        },
        brandingLocalStorageChanged() {
            this.brandingLocalStorage = new Date().getTime();
        },
        toggleSessionExpiration(){
            this.sessionExpired = !this.sessionExpired;
        },
        setSelectedUser(user){
            this.selectdedUser = user;
        },
        resetSessionExpiration(){
            this.sessionExpired = false;
        },
        async fetchSearch(params) {
            var that = this;
            if(params.filters == null || params.filters == undefined || params.filters == '' || params.filters.length < 3){
                this.showList = false;
                this.globalSearchItems = {};
                return;
            }
            try {
                let options = 
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ 
                            validation_key: params.k,
                            filter: params.filters,
                            method: "fetchSearch"
                        })
                    };
                    return middleware(url, options, ['isTokenAlive'])()
                    .then((response) => response.json())
                    .then((data) => {
                        if(data.errors) {
                            // TODO
                            that.showList = false;
                            return data;
                        }else {
                            that.globalSearchItems = data;
                            if(data.workfiles.length > 0 || data.vehicles.length > 0){
                                that.showList = true;
                            }
                        }
                        return data
                    })
                    .catch((error) => {
                        that.showList = false;
                    }
                );
            }
            catch (error) {
            }
        },
        deleteSearchList(){
            this.globalSearchItems = {}
            this.showList = false;
        }
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCommunicatorStore, import.meta.hot))
}