<script setup>
import { ref } from "vue";
import LocalStorage from '@/controller/LocalStorage.js';
import HumanizedDate from '@/components/common/utils/HumanizedDate.vue';
import YLoader from "../common/loaders/YLoader.vue";
import { matchNotificationToSvg, getNotificationColor } from "@/services/api/Mappings.js";
const props = defineProps({
    notification: Object,
    loading: Boolean
});
const emit = defineEmits("readOne");
const icons = {
    0: 'fa-solid fa-file-circle-plus',
    1: 'fa-solid fa-file-circle-check',
    2: 'fa-solid fa-file-circle-exclamation',
    3: 'fa-solid fa-plug-circle-plus',
    4: 'fa-solid fa-file-circle-plus',
    5: 'fa-solid fa-file-circle-check',
    6: 'fa-solid fa-file-circle-exclamation',
    7: 'fa-solid fa-file-circle-plus',
    8: 'fa-solid fa-file-circle-check',
    9: 'fa-solid fa-file-circle-plus',
    10: 'fa-solid fa-file-circle-check',
    11: 'fa-solid fa-file-circle-check',
    12: 'fa-solid fa-file-circle-check',
    13: 'fa-solid fa-file-circle-check'
}

const userColor = ref(LocalStorage.getColor());

</script>

<template>
    <div class="notification-card-wrapper" :class="[(props.notification.read == 1 ? 'read' : '')]">
        <div class="notification-body-wrapper">
            <div class="notification-image">
                <div class="notification-image-wrapper">
                    <div class="notification-image__icon" v-if="[0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].includes(props.notification.type)">
                        <router-link :to="/workfile/+props.notification.data.workfileId" title="Ver hoja de trabajo">
                            <div class="notification-type-icon" :style="{'background-color': getNotificationColor(props.notification.type)}" >
                                <img v-if="[4,5,6,13].includes(props.notification.type)" style="margin-left:-6px" v-bind:src="'/assets/workfiles/'+ matchNotificationToSvg(props.notification.type) +'.svg'" />
                                <img v-else v-bind:src="'/assets/workfiles/'+ matchNotificationToSvg(props.notification.type) +'.svg'" />
                            </div>
                        </router-link>
                    </div>
                    <div class="notification-image__icon" v-else-if="props.notification.type == 3 ">
                        <router-link :to="/invitation/+props.notification.data.invitationId" title="Ver invitación">
                            <font-awesome-icon :icon="icons[props.notification.type]" />
                        </router-link>
                    </div>               
                </div>
            </div>
            <div class="notification-body">
                <div class="notification-title" v-if="[0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].includes(props.notification.type)">
                    <i18n-t :keypath="'notifications'+'.'+props.notification.message" tag="p">
                        <template #or>
                            <router-link :to="/workfile/+props.notification.data.workfileId" title="">
                                <span class="notification-data--bold">{{ props.notification.data.orNumber }}</span>
                            </router-link>
                        </template>
                        <template #brand>
                            <router-link :to="/vehicle/+props.notification.data.vehicleId" title="">
                                <span class="notification-data--bold">{{ props.notification.data.vehicle.brand }}</span>
                            </router-link>
                        </template>
                        <template #model>
                            <router-link :to="/vehicle/+props.notification.data.vehicleId" title="">
                                <span class="notification-data--bold">{{ props.notification.data.vehicle.model }}</span>
                            </router-link>
                        </template>
                        <template #plate>
                            <router-link :to="/vehicle/+props.notification.data.vehicleId" title="">
                                <span class="notification-data--bold">{{ props.notification.data.vehicle.plate }}</span>
                            </router-link>
                            <br>
                        </template>
                        <template #vin>
                            <span class="notification-data--bold">{{ props.notification.data.vehicle.vin }}</span>
                            <br>
                        </template>
                    </i18n-t>
                </div>
                <div class="notification-title" v-else-if="props.notification.type == 3">
                    {{ $t('notifications.' + props.notification.message) }}
                </div>
                <div class="notification-additional-info">
                    <div class="notification-date">
                        <HumanizedDate :timestamp="props.notification.modified"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="notification-actions">
            <div class="notification-actions-wrapper">
                <div class="notification-actions__mark-read" v-if="props.notification.read == 0">
                    <div v-if="!loading" class="notification-actions-wrapper__icon--read" @click="emit('readOne', props.notification.id)" :title="$t('notifications.readOne')">
                        <font-awesome-icon icon="fa-regular fa-circle-dot" />
                    </div>
                    <div class="invitation__content_loader" v-if="loading">
                        <YLoader />
                    </div>                
                </div>
                <div class="notification-actions__mark-read" v-if="props.notification.read == 1"></div>
            </div>
        </div>
    </div>
</template>

<styles lang="scss" scoped>
@import "@/styles/colors.scss";
.notification-card-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    .notification-body-wrapper{
        display: flex;
        flex-direction: row;
        gap: 1rem;
        .notification-body a{
            text-decoration: none;
        }

    }
}
.notification-image{
    min-width: 35px;
    height: 35px;
    border-radius: 50%;
    flex-grow: 0;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #30A2FF;
    color: white;
    flex-basis: 35px;
    .notification-type-icon{
        width: 35px;
        height: 35px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 20px;
            height: 20px;
        }
    
    }
}
.notification-actions-wrapper{
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-size: 16px;
}
.notification-actions-wrapper__icon a {
    padding: 0;
}
.notification-title{
    margin-bottom: 0;
    p{
        margin-bottom: 0;
        font: normal normal normal 14px/19px 'CircularStd-Book';
        letter-spacing: -0.49px;
        color: #000000;
        opacity: 0.55;
    }
}
.notification-data--bold{
    font: normal normal normal 16px/19px 'CircularStd-Bold';
    letter-spacing: -0.8px;
    color: #153440;
    opacity: 1;
    text-decoration: none;
}
.notification-date{
    margin-top: -5px;
    margin-bottom: 10px;
    .humanized-date{
        font-size: 12px;
        color: $light-grey-descriptions;
    }
}
.notification-actions__mark-read:hover{
    cursor: pointer;
}
.notification-actions__mark-read{
    min-width: 20px;
    .custom-loader{
        margin-top: 8px;
        font-size: 16px;
    }
}
.notification-actions-wrapper__icon--read:hover{
    color: v-bind(userColor);
}
.notification-actions-wrapper__icon--read{
    margin-right: 30px;
}
.notification-image__icon a{
    color: #30A2FF;
    &:hover{
        color: white;
    }
}
.notification-image{
    background-color:white;
}
.notification-image:hover{
    .notification-image__icon a{
        color: white
    }
    background-color: #30A2FF;
}
.notification-actions-wrapper__icon--read:hover{
    color: v-bind(userColor);
}
.read{
    //background-color: #f4f4f5;
    border-radius: 15px;
}
</styles>