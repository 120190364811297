<script setup>
import { ref } from "vue";
import { useI18n } from 'vue-i18n';
import { useRouter } from "vue-router";
import FormController from "@/controller/FormController.vue";
import MazInput from 'maz-ui/components/MazInput';
import YBtn from '@/components/common/form/YBtn/YBtn.vue';
import { createPasswordRecovery } from '@/services/api/Users.js';
import { useToast } from 'maz-ui'
const { t } = useI18n();
const router = useRouter();
const showForm = ref(true);
const okMsg = ref('');
const koMsg = ref('');
const showOk = ref(false);
const showKo = ref(false);
const loaderButton = ref(false);
const errorPasssword = ref(false);
const okPasswordFormatIcon ="fa-solid fa-check";
const koPasswordFormatIcon = "fa-solid fa-xmark";
const i18n = useI18n();
const defaultLocale = ref(i18n.locale);
const passwordValid = ref(false);
const email = ref('');
const emailValid = ref(false);
const disabledButton = ref(false);
const envelope = '/assets/login/icons/envelope.svg';
function checkEmailFormat(newVal) {
    email.value = newVal;
    return emailValid.value = FormController.validEmail(newVal);
}
const toast = useToast()
function showToast (type, msg, options) {
    let toastMessage;
    if(type == 'error') {
        toastMessage = toast.error(msg, options);
    } else {
        toastMessage = toast.success(msg, options);
    }
    setTimeout(() => {
      toastMessage.close()
    }, options.timeout)
}
function recoverAccount() {
    loaderButton.value = true;
    disabledButton.value = true;
    createPasswordRecovery({ email: email.value})
    .then((res) => {
        loaderButton.value = false;
        disabledButton.value = false;
        showToast('success', t('register.create_password_recovery'), { position: 'top-right', timeout: 5000 })
    })
    .catch((err) => {
        loaderButton.value = false;
        disabledButton.value = false;
        showToast('error', t('register.create_password_recovery'), { position: 'top-right', timeout: 5000 })
    });
}
function goHome(){
  router.push('/login');
}

</script>

<template>
    <div class="">
        <template v-if="showForm">
            <form @submit.prevent="recoverAccount">
                <div class="form-group">
                    <MazInput
                        v-model="email"
                        :label="t('field.email')"
                        class="yauty-input"
                        autocomplete="off"
                        size="sm"
                        rounded-size="full"
                        contentClass="yauty-button"
                        :error="errorEmail"
                        :valid-button="emailValid"
                        v-on:keyup="emailValid = checkEmailFormat($event.target.value)"
                        >
                        <template #left-icon>
                            <img :src="envelope" alt="" style="width:16px;">
                        </template>
                    </MazInput>                    
                </div>
                <div class="form-group recover-buttons">
                    <YBtn 
                        size="sm"
                        color="primary" 
                        :loading="loaderButton"
                        :disabled="!emailValid"
                        :label="t('field.send')"
                        :rounded="true"
                        roundedSize="full"
                        type="button"
                        class="yauty-button"
                        @click="recoverAccount">
                    </YBtn>
                    <div class="login-pd" v-if="$route.name == 'recoverAccount'">
                        <label @click="goHome" style="padding-right:1px"> {{ $t("login.login_return") }}</label>
                    </div>                    
                </div>
            </form>
        </template>
        <template v-else>
            <div class="ok-container" v-if="showOk">
                <div class="login-pd" v-if="$route.name == 'recoverAccount'">
                    <label @click="goHome" style="padding-right:1px"> {{ $t("login.login_return") }}</label>
                </div> 
            </div>
            <div class="ko-container" v-if="showKo">
                <div class="login-pd" v-if="$route.name == 'recoverAccount'">
                    <label @click="goHome" style="padding-right:1px"> {{ $t("login.login_return") }}</label>
                </div> 
            </div>
        </template>
    </div>
</template>
<style lang="scss" scoped>
    .recover-buttons{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: start;
        justify-items: center;
        margin-top: 1rem;
    }
    .login-pd{
        margin-top: 29px;
        label{
        text-align: left;
        text-decoration: underline;
        font: normal normal normal 17px/29px 'CircularStd-Book';
        letter-spacing: -0.85px;
        color: #000000;
        opacity: 1;
        position: relative;
        overflow: hidden;
        display: inline-block;
        text-decoration: none;
        }
        label:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: -100%;
        width: 100%;
        height: 1px;
        background: #333;
        transition: left .8s;
        }
        label:hover:after {
        left: 0;
        }
        label:hover{
        cursor: pointer;
        }
    }  
    .yauty-button{
        width: 240px;
    }  
    .yauty-input{
        width: 330px;
    } 

    @media (max-width:576px) {
        .form-group{
            .m-input.--has-label.yauty-input.--primary.--sm {
                width: 100%;
            }
        }
        .recover-buttons{
            max-width: 100%;
        }
    }

    @media (min-width: 577px) and (max-width: 1024px) and (orientation: portrait) {
        .form-group.recover-buttons {
            align-items: center;
        }
    }
</style>