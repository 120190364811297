<script setup>
import { ref, watch, onMounted, onUnmounted } from "vue";
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import LocalStorage from '@/controller/LocalStorage.js';
import YBtn from "../../common/form/YBtn/YBtn.vue";
import WorkfileReparationTime from "./components/WorkfileReparationTime.vue";
import WorkfileActions from "./components/WorkfileActions.vue";
import WorkfileStatusList from "./components/WorkfileStatusList.vue";
import WorkfileVehicleLogo from "./components/WorkfileVehicleLogo.vue";
import WorkfileVehicleInfo from "./components/WorkfileVehicleInfo.vue";
import WorkfilesDates from "./components/WorkfilesDates.vue";
import WorkfilesClient from "./components/WorkfilesClient.vue";
import WorkfilesBranch from "./components/WorkfilesBranch.vue";
import WorkfileInvoices from "./components/WorkfileInvoices.vue";
const back = '/assets/workfiles/back.svg';
const props = defineProps({
  workfile: Object
});

const isExpanded = ref(false);
const toggleExpand = () => {
      isExpanded.value = !isExpanded.value;
    };

const toggleExpandAll = () => {
  isExpanded.value = !isExpanded.value;
};
const isMobileView = ref(()=> window.innerWidth <= 576);
const isVerticalTabletView = ref(()=> window.innerWidth > 576 && window.innerWidth <= 768);
const isDesktopView = ref(()=> window.innerWidth > 768);
const updateView = () => {
  isMobileView.value = window.innerWidth <= 576;
  isVerticalTabletView.value = window.innerWidth > 576 && window.innerWidth <= 768;
  isDesktopView.value = window.innerWidth > 768;
};

onMounted(() => {
  window.addEventListener('resize', updateView);
});
onUnmounted(() => {
  window.removeEventListener('resize', updateView);
});

const userColor = ref(LocalStorage.getColor());
const { t } = useI18n();
const show = ref(false);

const less = "/assets/svg/-.svg";
const more = "/assets/svg/+.svg";
watch(() => props.workfile, () => {
  if(props.workfile){
    show.value = true;
  }
});
</script>

<template>
    <div class="workfile-body-wrapper" v-if="show">
        <div class="navigation-wrapper">
            <YBtn class="navigation" @click="$router.go(-1)" variant="link" :noUnderline="true" contentClass="navigation">
                <img :src="back" alt="" style="height:1rem;">
                <span>{{ $t('field.back') }}</span>
            </YBtn>
        </div>
        <div class="workfile-body">
            <div class="icon-wrapper" @click="toggleExpand">
                <div class="icon" :class="{ expanded: isExpanded }">
                    <span v-if="!isExpanded"><img :src="more" style="width: 23px;"/></span>
                    <span v-else><img :src="less" style="width: 18px; height: 33px;"/></span>
                </div>
                <div class="line" :class="{ expanded: isExpanded }"></div>
            </div>
            <div v-if="isMobileView" class="body-mobile-info" :class="{ 'show': isExpanded }">
                <div class="workfile-dates">
                    <WorkfilesDates 
                        :modified="props.workfile?.date"
                        :schedule="props.workfile?.scheduleDate" 
                        :reparation="props.workfile?.reparationDate" 
                        :delivery="props.workfile?.deliveryDate" 
                        :type="props.workfile?.type"
                        :reparationTime="props.workfile?.reparationTime"/>
                </div>
                <div class="workfile-client">
                    <WorkfilesClient :client="props.workfile?.client"/>
                </div>
                <div class="workfile-branch">
                <WorkfilesBranch :branch="props.workfile?.data?.branch"/>
                </div>
            </div>
            <div v-if="isMobileView" class="body-mobile-actions" :class="{ expanded: isExpanded }">
                <WorkfileActions :actions="props.workfile?.actions" :outOfStock="props.workfile?.outOfStock"/>
            </div>
            <div class="workfile-vehicle-logo-container">
                <WorkfileVehicleLogo :vehicle="props.workfile?.vehicle"/>
            </div>
            <div class="workfile-vehicle-info">
                <WorkfileVehicleInfo :vehicle="props.workfile?.vehicle" :or="props.workfile?.orNumber"/>
            </div>
            <div class="workfile-dates">
                <WorkfilesDates 
                    :modified="props.workfile?.date"
                    :schedule="props.workfile?.scheduleDate" 
                    :reparation="props.workfile?.reparationDate" 
                    :delivery="props.workfile?.deliveryDate" 
                    :type="props.workfile?.type"
                    :reparationTime="props.workfile?.reparationTime"/>
            </div>
            <div class="workfile-client">
                <WorkfilesClient :client="props.workfile?.client"/>
            </div>
            <div class="workfile-branch">
                <WorkfilesBranch :branch="props.workfile?.data?.branch"/>
            </div>
            <div class="workfile-actions-wrapper">
                <WorkfileActions :actions="props.workfile?.actions" :outOfStock="props.workfile?.outOfStock"/>
            </div>
            <div class="workfile-invoices">
                <WorkfileInvoices :invoices="props.workfile?.data?.invoices"/>
            </div>
            <div class="workfile-reparation-time-wrapper defaultScreen">
                <WorkfileReparationTime :time="props.workfile?.reparationTime" :statusLog="props.workfile?.data?.workfile_status_log" :type="props.workfile?.type"/>
            </div>
            <div class="workfile-status-line-wrapper defaultScreen">
                <WorkfileStatusList :log="props.workfile?.data?.workfile_status_log" :documents="props.workfile?.data?.documents" :type="props.workfile?.type"/>
            </div>
            <div class="status-container" :class="{ expanded: isExpanded }">
                    <WorkfileReparationTime :time="props.workfile?.reparationTime" :statusLog="props.workfile?.data?.workfile_status_log" :type="props.workfile?.type" :isExpanded="isExpanded" @toggle-expand="toggleExpandAll"/>
                    <div class="status-container-scroll">
                    <WorkfileStatusList :log="props.workfile?.data?.workfile_status_log" :documents="props.workfile?.data?.documents" :type="props.workfile?.type" :isExpanded="isExpanded" @toggle-expand="toggleExpandAll"/>
                    </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.body-mobile-info, .status-container, .body-mobile-actions{
    display: none;
}
.navigation-wrapper{
    display: flex;
    justify-content: start;
    align-items: center;
    padding-bottom: 5px;
    height: 40px;
    padding-left: 10px;
    .navigation{
        display: flex;
        gap: 1rem;
        align-items: center;
        & *{
            color: #7E8E94;
        }
        padding-bottom: 0;
        width: fit-content;
    }
    .navigation:hover{
        cursor: pointer;
        img {
            animation: arrow 700ms linear infinite;
        }
    }
}
@keyframes arrow {
    0% {right: 0px}
    50% {right: 10px}
    100% {right: 0px}
}
.workfile-body{
    border-radius: 25px;
    display: grid;
    flex-direction: row;
    background-color: white;
    padding-bottom: 2rem;
    padding-right: 3rem;
    
    @media only screen and (max-width: 686px) {
        grid-template-columns:48% 48% 1fr;
        grid-template-rows: 50px 1fr;
        grid-template-areas: 
        ".                .                 ."
        "vehicle-logo     dates             ."
        "vehicle-info     vehicle-info      ." 
        "client           branch            ."
        "invoices         .                 ."
        "actions          actions           ."
        "status-line      status-line       .";
    }
    @media only screen and (max-width: 1366px) {
        grid-template-columns:48% 30% 1fr;
        grid-template-rows: 50px 1fr;
        grid-template-areas: 
        ".                .                 ."
        "vehicle-logo     dates             ."
        "vehicle-info     .                 ." 
        "client           branch            ."
        "invoices         .                 ."
        "actions          actions           ."
        "status-line      status-line       .";
    }
    @media (min-width: 769px) and (max-width: 990px) and (orientation: landscape) {
        grid-template-columns:40% 30% 1fr;
        grid-template-rows: 50px 1fr;
        grid-template-areas: 
        ".                .                 ."
        "vehicle-logo     dates             ."
        "vehicle-info     .                 ." 
        "client           branch            ."
        "invoices         .                 ."
        "actions          actions           ."
        "status-line      status-line       .";
    }
   
    @media only screen and (max-width: 1280px) and (orientation:portrait){
        grid-template-columns:48% 48% 1fr;
        grid-template-rows: 50px 1fr;
        grid-template-areas: 
        ".                .                 ."
        "vehicle-logo     dates             ."
        "vehicle-info     .                 ." 
        "client           branch            ."
        "invoices         .                 ."
        "actions          actions           ."
        "status-line      status-line       .";
    }
    @media only screen and (min-width: 1365px) {
        grid-template-columns: 130px 180px 215px 1fr 1fr 100px 250px;
        // grid-template-rows: 50px minmax(100px, 200px) 50px 1fr 50px;
        grid-template-areas: 
        ".            .            .            .            .            .         reparation-time" 
        "vehicle-logo vehicle-info dates        client       branch       invoices  status-line"    
        "actions      actions      actions      actions      actions      .         status-line"
        ".            .            .            subtotal     subtotal     .         status-line";
    }
    @media only screen and (min-width: 1680px) {
        grid-template-columns: 170px 200px 300px 1fr 1fr 100px 300px;
    }


    .workfile-navigation-wrapper{
        grid-area: navigation;
        justify-self: end;
        align-self: start;
        padding-top: 1rem;

    }
    .workfile-navigation-wrapper:hover{
        cursor: pointer;
    }
    .workfile-info-wrapper{
        grid-area: general-info;
    }
    .workfile-actions-wrapper{ 
        grid-area: actions;
    }
    .workfile-reparation-time-wrapper{
        grid-area: reparation-time;
    }
    .workfile-status-line-wrapper{
        grid-area: status-line;
    }
    .workfile-actions-total-wrapper{
        grid-area: subtotal;
    }
    .workfile-vehicle-logo-container{
        grid-area: vehicle-logo;
    }
    .workfile-vehicle-info{
        grid-area: vehicle-info;
    }
    .workfile-dates{
        grid-area: dates;
        margin-top: 3px;
    }
    .workfile-client{
        grid-area: client;
    }
    .workfile-branch{
        grid-area: branch;
    }
    .workfile-invoices{
        grid-area: invoices;
    }
}

@media (max-width:576px) {
    .workfile-client, .workfile-branch, .workfile-dates, .workfile-actions-wrapper, .workfile-reparation-time-wrapper,.workfile-status-line-wrapper, .workfile-invoices{
        display: none;
    }
    .body-mobile-info{
        .workfile-client, .workfile-branch, .workfile-dates, .workfile-actions-wrapper, .workfile-reparation-time-wrapper,.workfile-status-line-wrapper{
            display: block;
        }
    }
    .navigation-wrapper{
        display: none;
    }
    .workfile-body {
        background-color: transparent;
    }
    .workfile-reparation-time-wrapper{
        display: none;
    }
    .workfile-vehicle-logo-container{
        display: none;
    }
    .icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    position: relative;
    }
    .icon{
        position: absolute;
        z-index: 5;
        left: -30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #07CCB9;
        border-radius: 100%;
        width: 31px;
        height: 31px;
        transition: transform 0.3s;
        top: 40%;
    }
    .icon.expanded {
        transform: translateY(420px);
    }
    .line {
    position: absolute;
    left: -16px;
    width: 2px;
    height: 100px;
    top: 40%;
    background-color: #07CCB9; 
    transition: height 0.3s;
    }

    .line.expanded {
    height: 440px; 
    }
    .body-mobile-info {
        display: block;
        position: absolute;
        top: 95px;
    }
    .workfile-body .workfile-client{
        right: 17.5px;
    }
    .workfile-branch {
        padding-left: 0.4rem;
    }
    .body-mobile-info{
        opacity: 0;
        transition: opacity 0.3s ease;
        height: 0;
        overflow: hidden;
    }
    .body-mobile-info.show {
        opacity: 1;
        height: auto;
        }
        
    .body-mobile-actions{
        display: block;
        position: absolute;
        top: 140px;
        left: -55px;
    }
    .body-mobile-actions.expanded {
        transform: translateY(315px);
    }
}

@media (min-width: 577px) and (max-width: 1280px) and (orientation:portrait){
    
    ::-webkit-scrollbar {
    width: 2px !important; 
    }
    ::-webkit-scrollbar-thumb {
    background: #8D939E; 
    }
    ::-webkit-scrollbar-thumb:hover {
    background: #555 !important; 
    }
    ::-webkit-scrollbar-track {
    background: #E5E8EC;
    }
    .defaultScreen, .body-mobile-actions{
        display: none;
    }
    .status-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        right: 16px;
        min-width: 80px;
    }
    .status-container-scroll{
        padding-right: 10px;
        max-height: 488px;
        overflow-y: auto;
    }
    .status-container.expanded{
        right: 0;
        min-width: 232px;
        background-color: #FFFFFF !important;
        z-index: 999;
        border-radius: 24px;
        box-shadow: 0px 0px 18pt rgba(0, 0, 0, 0.2);
    }
    .workfile-body-wrapper{
        padding-top: 7dvh;
        height: 100dvh;
        .navigation-wrapper{
            justify-content: end;
            padding-right: 25px;
            margin-bottom: 15px;
            padding-top: 15px;
        }
        .workfile-client, .workfile-invoices{
            padding-left: 1.5rem;
        }
        .workfile-branch{
            padding-left: 0.5rem;
        }
        .workfile-vehicle-logo-container{
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 0;
            left: 35px;
            width: 50px;
            height: 50px;
        }
    
    }
}

@media (min-width: 769px) and (max-width: 1367px) and (orientation: landscape) {
    ::-webkit-scrollbar {
    width: 3px !important; 
    }
        
    ::-webkit-scrollbar-thumb:hover {
    background: #555 !important; 
    }
    .defaultScreen{
        display: none;
    }
    .status-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        right: 30px;
        width: 232px;
    }
    .status-container-scroll{
        padding-right: 10px;
        max-height: 488px;
        overflow-y: auto;
    }
    .workfile-body-wrapper{
        .navigation-wrapper{
            justify-content: end;
            padding-right: 25px;
            margin-bottom: 15px;
        }
        .workfile-client, .workfile-invoices{
            padding-left: 1.5rem;
            top: 0px;
        }
        .workfile-branch{
            padding-left: 0.5rem;
            top: -35px;
        }
    }
}
</style>