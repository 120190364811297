<script setup>
import { computed, ref, watch } from "vue";
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import LocalStorage from '@/controller/LocalStorage.js';

const props = defineProps({
  invoices: Object,
});

const userColor = ref(LocalStorage.getColor());
const { t } = useI18n();
const router = useRouter();

const factures = computed(() => {
    return Array.from({ length: 5 }, (_, i) => props.invoices[0]); //fake per maquetar
});

</script>

<template>
    <div class="workfile-invoices">
        <div class="workfile-invoices-title">
            <span class="subtitle">{{ t('field.invoices') }}</span>
        </div>
        <div class="workfile-invoices-content">
            <div class="invoice" v-for="invoice in props.invoices">
                <a :href="invoice.link">
                    <img src="/assets/svg/factura.svg"/>
                </a>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.workfile-invoices{
  .subtitle{
    font-family: 'CircularStd-Bold';
    color: #153440;
    opacity: 0.42;
    font-size: 14px;
  }
  .workfile-invoices-content{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 2px;
  }
  .invoice a{
        height: 1.5dvw;
        > img:hover {
            filter: invert(49%) sepia(23%) saturate(274%) hue-rotate(181deg) brightness(93%) contrast(87%);
            transition: 0.3s linear;
        }
  }
  img{
    height: 30px;
  }
}
@media (max-width:576px) {

}
@media (min-width: 577px) and (max-width: 1280px) and (orientation: portrait) {
    .workfile-invoices{
        .subtitle{
            font-size: 21px;
        }
    }
}
@media (min-width: 769px) and (max-width: 1367px) and (orientation: landscape) {
    .workfile-invoices{
        .subtitle{
            font-size: 21px;
        }
    }
}
</style>