<script setup>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import LocalStorage from '@/controller/LocalStorage.js';
import MazDialog from 'maz-ui/components/MazDialog';
import YBtn from '@/components/common/form/YBtn/YBtn.vue';
const { t } = useI18n();
const props = defineProps({
  show: Boolean
});

const emit = defineEmits(['update:show']);

function handleClose() {
  showDialog.value = false;
  // emit('hide');
}
const showDialog = computed({
  get() {
    return props.show;
  },
  set(value) {
    // Emite un evento para actualizar el valor en el componente padre
    emit('update:show', value);
  }
});
</script>

<template>
    <!-- <transition name="modal"> -->
        <MazDialog v-model="showDialog" title="Dialog Title" @close="handleClose" class="legal_modal" id="legal">
            <template #title>
                <div class="d-flex align-items-center title-wrapper">
                    <div class="legal-modal-title">
                        {{ $t("use.title") }}
                    </div>
                </div>
            </template>
            <template #default>
              <section class="legal-wrapper">
                <article class="legal-block">
                  <div class="legal-subtitle">
                    {{ $t('use.text_1_title') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_1_1') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_1_2') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_1_3') }}
                  </div>
                </article>
                <article class="legal-block">
                  <div class="legal-subtitle">
                    {{ $t('use.text_2_title') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_2_1') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_2_2') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_2_3') }}
                  </div>
                </article>
                <article class="legal-block">
                  <div class="legal-subtitle">
                    {{ $t('use.text_3_title') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_3_1') }}
                  </div>
                </article>
                <article class="legal-block">
                  <div class="legal-subtitle">
                    {{ $t('use.text_4_title') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_4_1') }}
                  </div>
                </article>
                <article class="legal-block">
                  <div class="legal-subtitle">
                    {{ $t('use.text_5_title') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_5_1') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_5_2') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_5_3') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_5_4') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_5_5') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_5_6') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_5_7') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_5_8') }}
                  </div>
                </article>
                <article class="legal-block">
                  <div class="legal-subtitle">
                    {{ $t('use.text_6_title') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_6_1') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_6_2') }}
                  </div>
                </article>
                <article class="legal-block">
                  <div class="legal-subtitle">
                    {{ $t('use.text_7_title') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_7_1') }}
                  </div>
                  <div class="legal-text-2PL">
                    {{ $t('use.text_7_1_1') }}
                  </div>
                  <div class="legal-text-2PL">
                    {{ $t('use.text_7_1_2') }}
                  </div>
                  <div class="legal-text-2PL">
                    {{ $t('use.text_7_1_3') }}
                  </div>
                </article>
                <article class="legal-block">
                  <div class="legal-subtitle">
                    {{ $t('use.text_8_title') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_8_1') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_8_2') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_8_3') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_8_4') }}
                  </div>
                </article>
                <article class="legal-block">
                  <div class="legal-subtitle">
                    {{ $t('use.text_9_title') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_9_1') }}
                  </div>
                </article>
                <article class="legal-block">
                  <div class="legal-subtitle">
                    {{ $t('use.text_10_title') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_10_1') }}
                  </div>
                </article>
                <article class="legal-block">
                  <div class="legal-subtitle">
                    {{ $t('use.text_11_title') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_11_1') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_11_2') }}
                  </div>
                </article>
                <article class="legal-block">
                  <div class="legal-subtitle">
                    {{ $t('use.text_12_title') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_12_1') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_12_2') }}
                  </div>
                </article>
                <article class="legal-block">
                  <div class="legal-subtitle">
                    {{ $t('use.text_13_title') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_13_1') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_13_2') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_13_3') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_13_4') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_13_5') }}
                  </div>
                </article>
                <article class="legal-block">
                  <div class="legal-subtitle">
                    {{ $t('use.text_14_title') }}
                  </div>
                  <div class="legal-text-2">
                    {{ $t('use.text_14_1') }}
                  </div>
                </article>
              </section>
            </template>
            <template #footer>

            </template>
        </MazDialog>
    <!-- </transition> -->
</template>

<style lang="scss">
  .legal-wrapper {
    padding-bottom: 230px;
    max-width: 990px;
    margin: 0 auto;
    .legal-title {
      font-size: 60px;
      letter-spacing: -3px;
      font-family: 'CircularStd-Black';
      padding-bottom: 55px;
    }
    .legal-block {
      font-size: 18px;
      letter-spacing: -0.9px;
      padding-bottom: 7px;
      .legal-subtitle {
        font-size: 30px;
        letter-spacing: -1.5px;
        font-family: 'CircularStd-Bold';
        padding-bottom: 10px;
      }
      .legal-text-1 {
        padding-bottom: 0px;
      }
      .legal-text-2 {
        padding-bottom: 25px;
      }
      .legal-text-3 {
        padding-bottom: 50px;
      }
      .legal-text-2PL {
        padding-left: 2rem;
        padding-bottom: 25px;
      }
      a {
        color: #0047ba;
        text-decoration: none;
        position: relative;
      }
      a:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        border-radius: 4px;
        background-color: #0047ba;
        bottom: -2px;
        left: 0;
        transform-origin: right;
        transform: scaleX(0);
        transition: transform 0.3s ease-in-out;
      }
      a:hover:before {
        transform-origin: left;
        transform: scaleX(1);
      }
    }
  }
  .m-dialog.legal_modal{
    max-height: 80dvh !important;
  }
  #dialogDesc.m-dialog-content {
    overflow-y: auto;
    padding-top: 0 !important;
    // border-bottom-left-radius: 34px;
    // border-bottom-right-radius: 34px;
  }
  #legal *::webkit-scrollbar{
    width: 100px;
  }
  .m-dialog-header{
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .m-dialog-closebtn svg:hover{
    path{
      stroke-width: 3;
    }
  }
  .m-dialog-header .legal-modal-title{
    font-size: 26px;
    font-family: 'CircularStd-Black';
  }
  .m-dialog-header.--has-title{
    padding-bottom: 40px !important;
  }
  .m-dialog.legal_modal .m-dialog-footer{
    padding: 30px !important;
  }

  @media (max-width: 576px) {
    .m-dialog-header.--has-title{
    align-items: flex-start;
  }
  .m-btn.--is-button.--transparent:not(:disabled){
    padding: 0px 0px 10px 10px !important;
  }
}
</style>