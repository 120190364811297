<script setup>
import { onMounted, watch, ref } from "vue";
import { useI18n } from 'vue-i18n';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import LocalStorage from '@/controller/LocalStorage.js';
const { t } = useI18n();
const props = defineProps({
  actions: Array,
  outOfStock: [String, Number]
});
const userColor = ref(LocalStorage.getColor());
const sparepartsPending = t('field.missing_parts');
const outOfStock = ref(false);
const isExpanded = ref(false);
const toggleExpand = () => {
      isExpanded.value = !isExpanded.value;
    };
watch(() => props.actions, () => {

});
onMounted(() => {
  if(props.outOfStock && props.outOfStock == 1){
    outOfStock.value = true;
  }
});
//:class="{ 'show': isExpanded }"
</script>

<template>
  <!---->
  <div class="actions-mobile-wrapper" >
    <div class="action-container" :class="{ expanded: isExpanded }">
      <div class="subtitle" >
        {{ t('actions.actions') }} 
        <span
          v-if="outOfStock"
          v-popoverr 
          data-bs-toggle="popover" 
          data-bs-trigger="hover"
          :data-bs-content="sparepartsPending"
          data-bs-placement="right"
          data-bs-html="false" 
          class="spareparts-pending">
          <font-awesome-icon icon="fa-solid fa-circle-exclamation"/>
        </span>
          <div class="icon" :class="{ expanded: isExpanded }" @click="toggleExpand">
                      <font-awesome-icon icon="chevron-down" class="chevron-down" v-if="!isExpanded" />
                      <font-awesome-icon icon="chevron-down" class="chevron-up" v-else/>
          </div>
        </div>
      <div class="action-list" :class="{ 'show': isExpanded }">
        <div class="action-row" v-for="action in props.actions">
          <!-- <font-awesome-icon icon="fa-solid fa-chevron-right" /> -->
          <div class="action-description">{{ action.description }}</div>
          <!-- <font-awesome-icon icon="fa-solid fa-circle-xmark"/> -->
        </div>
      </div>
    </div>
    </div>
    <div class="workfile-actions">
      <div class="subtitle">
        {{ t('actions.actions') }} 
        <span
          v-if="outOfStock"
          v-popoverr 
          data-bs-toggle="popover" 
          data-bs-trigger="hover"
          :data-bs-content="sparepartsPending"
          data-bs-placement="right"
          data-bs-html="false" 
          class="spareparts-pending">
          <font-awesome-icon icon="fa-solid fa-circle-exclamation"/>
        </span>
      </div>
      <div class="action-list">
        <div class="action-row" v-for="action in props.actions">
          <!-- <font-awesome-icon icon="fa-solid fa-chevron-right" /> -->
          <div class="action-description">{{ action.description }}</div>
          <!-- <font-awesome-icon icon="fa-solid fa-circle-xmark"/> -->
        </div>
      </div>
    </div>
</template>

<style lang="scss" scoped>
.actions-mobile-wrapper{
  display: none;
}
.workfile-actions, .actions-mobile-wrapper{
  padding: 4rem;
  @media only screen and (max-width: 998px) {
    padding: 2rem;
    margin-bottom: 0.5rem;
  }
  @media only screen and (min-width: 1680px) {
    padding: 4rem;
    padding-top: 2rem;
  }
  .subtitle{
    margin-bottom: 1.5rem;
    padding-left: 1rem;
    font: normal normal normal 22px/19px 'CircularStd-Bold';
    letter-spacing: 0px;
    color: #153440;
    opacity: 1;
    .spareparts-pending{
      color: #E29722;
    }
  }
  .action-list{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .action-row{
      background-color: #F2F2F2;
      border: 1px solid #F2F2F2;
      border-radius: 23px;
      opacity: 1;
      padding-left: 1rem;
      padding-right: 1rem;
      height: auto;
      padding: 7px 20px;
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 1rem;
      .action-description{
        font: normal normal normal 20px/19px 'CircularStd-Light';
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        flex-grow: 1;
        font-size: 16px;
      }
    }
    // .action-row > :first-child,
    // .action-row > :last-child{
    //   color: #ACB4C1;
    // }
  }
}
@media (max-width: 576px) {
  .actions-mobile-wrapper{
  display: block;
  }
  .workfile-actions{
    display: none;
  }
  .subtitle{
    display: flex;
    width: 100%;
    padding: 10px 0px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 0rem;
  }
  .action-container{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    padding: 0px 25px;
    border-radius: 24px;
    background-color: #FFFFFF;
    width: calc(100dvw - 50px);
    height: 47px;
  }
  .action-container.expanded{
    height: auto;
    padding-bottom: 25px;
  }
  .chevron-down{
    color: v-bind(userColor);
  }
  .chevron-up{
    color: v-bind(userColor);
    transform: rotate(180deg);
  }
  .action-list{
    opacity: 0;
    transition: opacity 0.3s ease;
    height: 0;
    overflow: hidden;
  }
  .action-list.show {
    opacity: 1;
    height: auto;
    margin-left: 6px;
  }
}

 
</style>