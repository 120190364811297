<script setup>
import { defineProps, ref } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useNotificationStore } from '@/stores/notification.js';
import { notificationsReadAll } from '@/services/api/Notifications.js';
import MazDropdown from 'maz-ui/components/MazDropdown';
import LocalStorage from '@/controller/LocalStorage.js';
import NotificationsCards from "@/components/notifications/NotificationsCards.vue";
import YBtn from '@/components/common/form/YBtn/YBtn.vue';
const front = '/assets/svg/front.svg';
const notificationStore = useNotificationStore();
const notifications = storeToRefs(notificationStore);
const { getNotifications: notificationList, getNotificationsUnread: notificationUnreadList, getNotificationsCount: notificationsCount} = storeToRefs(notifications);
const showUnread = ref(true);
const notificationsFilteredList = ref();
const userKey = ref(LocalStorage.getKey());
const loading = ref(false);
const bell = '/assets/layout/bell.svg';
const { t } = useI18n();
const userColor = ref(LocalStorage.getColor());
const readOneLoader = ref(false);
const loadingIds = ref({});


function getNotifications(){
    return showUnread.value ? notificationList.value.filter((notification)=>notification.read == 0)
                                    : notificationList.value;
}

function filterNotifications(){
    notificationsFilteredList.value = getNotifications();
}

function readAll(){
    loading.value = true;
    let ids = notificationList.value.map((notification)=>notification.id);
    notificationsReadAll({k: userKey.value, notifications: ids}).then((res)=>{
    }).then(()=>{
        loading.value = false;
        notificationStore.fetchAll({k: userKey.value});
    }).catch((err)=>{
        console.log('err',err);
    });
}

function readOne(id){
    readOneLoader.value = true;
    loadingIds.value[id] = true;
    notificationsReadAll({k: userKey.value, notifications: Object.keys(loadingIds.value)}).then((res)=>{
        loadingIds.value[id] = false;
        readOneLoader.value = false;
    }).then(()=>{
        notificationStore.fetchAll({k: userKey.value}).then((res)=>{

        }).catch((err)=>{
            console.log('err fetchall',err);
        });
    }).catch((err)=>{
        console.log('err',err);
    })
}

const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
</script>

<template>
    <div class="notifications-dropdown desktop" id="notifDropdown">
        <MazDropdown
            trigger="hover"
            size="xs"
            position="bottom right"
            >
            <template #default>
                <div class="bell-wrapper">
                    <div class="notifications-icon" :title="$t('field.notifications')">
                        <img :src="bell" alt="" class="bell">
                    </div>
                    <div class="notifications-label">
                        <span>{{ $t('field.notifications') }}</span>
                    </div>
                    <div class="notifications-count" v-if="notificationsCount > 0">
                        <span>{{ notificationsCount }}</span>
                    </div>
                </div>
            </template>
            <template #dropdown>
                <div class="notifications-body">
                    <div class="title">
                        <div class="title-label">
                            {{ t('field.notifications') }}
                        </div>
                    </div>
                    <div class="actions">
                        <div class="mark-all-read light-grey" @click="readAll()">{{$t("notifications.readAll")}}</div>
                    </div>
                    <div class="notifications-list">
                        <NotificationsCards :notifications="showUnread ? notificationUnreadList : notificationList"/>
                    </div>
                    <div class="notifications-footer">
                        <router-link to="/notifications" class="btn-header-route" title="Mostrar en pantalla completa">
                                <div class="show-all-wrapper">
                                    <span>Mostrar totes</span>
                                </div>
                        </router-link>
                    </div>
                </div>
            </template>
        </MazDropdown>
    </div>
    <div class="notifications-dropdown touch" id="notifDropdown">
        <MazDropdown
            trigger="click"
            size="xs"
            position="bottom right"
            >
            <template #default>
                <div class="bell-wrapper">
                    <div class="notifications-icon" :title="$t('field.notifications')">
                        <img :src="bell" alt="" class="bell">
                    </div>
                    <div class="notifications-label">
                        <span>{{ $t('field.notifications') }}</span>
                    </div>
                    <div class="notifications-count" v-if="notificationsCount > 0">
                        <span>{{ notificationsCount }}</span>
                    </div>
                </div>
            </template>
            <template #dropdown>
                <div class="notifications-body">
                    <div class="title">
                        <div class="title-label">
                            {{ t('field.notifications') }}
                        </div>
                    </div>
                    <div class="actions">
                        <div class="mark-all-read light-grey" @click="readAll()">{{$t("notifications.readAll")}}</div>
                    </div>
                    <div class="notifications-list">
                        <NotificationsCards :notifications="showUnread ? notificationUnreadList : notificationList"/>
                    </div>
                    <div class="notifications-footer">
                        <router-link to="/notifications" class="btn-header-route" title="Mostrar en pantalla completa">
                                <div class="show-all-wrapper">
                                    <span>Mostrar totes</span>
                                </div>
                        </router-link>
                    </div>
                </div>
            </template>
        </MazDropdown>
    </div>
</template>

<style lang="scss" scoped>
@import "@/styles/colors.scss";
.touch{
    display: none !important;
}
.bell-wrapper{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .notifications-icon{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            max-width: 29px;
            max-height: 29px;
            width: auto;
            height: auto;
            object-fit: contain;
        }
    }
    .notifications-count{
        position: absolute;
        top: -5px;
        left: 15px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: v-bind(userColor);
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 12px;
    }
    .notifications-label{
        margin-left: 10px;
        font-size: 16px;
        font-weight: 500;
        font-family: 'CircularStd-Book';
        color: #cbd5e1;
    }
}
.notifications-body{
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem;
    min-height: 200px;
    min-width: 400px;
    .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title-label{
            font: normal normal normal 26px/19px 'CircularStd-Bold';
            letter-spacing: 0px;
            color: #153440;
            margin-bottom: 1rem;
        }
    }
    .actions{
        display: flex;
        justify-content: flex-end;
        font-size: 11px;
        .mark-all-read{
            margin-bottom: 0.5rem;
        }
        .mark-all-read:hover{
            cursor: pointer;
            text-decoration: underline;
        }
        .light-grey{
            color: $light-grey-descriptions;
        }
    }
    .notifications-list{
        flex-grow: 1;
        margin-top: 1rem;
    }
    .notifications-footer{
        display: flex;
        margin-top: 1rem;
        justify-content: center;
        align-items: center;
        a{
            text-decoration: none;
            color: #153440;
            font: normal normal normal 16px/19px 'CircularStd-Bold';
        }
    }
}

@media (max-width: 1900px) {
    .touch{
    display: block !important;
    }
    .desktop{
        display: none !important;
    }
}
// @media (min-width: 577px) and (max-width: 1024px) and (orientation: portrait) {
//     .notifications-dropdown{
//         z-index: 1001;
//         position: absolute;
//         top: -18px;
//         right: 30px;
//     }
// }

@media (min-width:577px) and (max-width: 686px){
    .notifications-body{
        max-width: 450px;
    }
}
</style>