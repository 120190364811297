<script setup>
import { ref, watch } from "vue";
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import LocalStorage from '@/controller/LocalStorage.js';

const props = defineProps({
  client: Object,
});

const userColor = ref(LocalStorage.getColor());
const { t } = useI18n();
const router = useRouter();
// email:"amarimon@cloudactivereception.com"
// firstname:"Albert"
// lastname:"Marimon"
// mobile:"+34615291669"
// secondLastname:"Aragonès"
// vatNumber:"46756847D"
</script>

<template>
    <div class="workfile-client">
        <div class="workfile-client-title">
            <span class="subtitle">{{ t('client.client') }}</span>
        </div>
        <div class="workfile-client-content">
            <div class="workfile-client-content-item bold">
                <span class="bold">{{ props.client.firstname + ' ' + props.client.lastname }}</span>
            </div>
            <div class="workfile-client-content-item">
                <span>{{ props.client.email }}</span>
            </div>
            <div class="workfile-client-content-item">
                <span>{{ props.client.mobile }}</span>
            </div>
            <div class="workfile-client-content-item">
                <span>{{ props.client.vatNumber }}</span>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.workfile-client{
  @media only screen and (max-width: 1279px) {
    padding-left: 1.5rem;
    margin-bottom: 0.5rem;
  }
  .subtitle{
    font-family: 'CircularStd-Bold';
    color: #153440;
    opacity: 0.42;
    font-size: 14px;
  }
  .workfile-client-content{
    font: normal normal normal 14px/19px 'CircularStd-Book';
    letter-spacing: 0px;
    color: #000000;
    padding-right: 1rem;
    word-break: break-all;
  }
  .bold{
    font-family: 'CircularStd-Bold';
    font-size: 14px;
  }
 
}
@media (max-width:576px) {
    .workfile-client-content{
      font-size: 18px !important;
      line-height: 1.3 !important;
    }
    .workfile-client-title {
      display: none;
      }
      .workfile-client .bold {
        font-family: "CircularStd-Bold";
        font-size: 18px;
      }
      .workfile-client-content-item {
        font-family: 'CircularStd-Book';
        font-size: 15px;
      }
  }
  @media (min-width: 577px) and (max-width: 1280px) and (orientation: portrait) {
    .workfile-client {
    }
    .workfile-client-content{
      font-size: 18px !important;
      .workfile-client-content-item.bold {
        span.bold {
          font-family: 'CircularStd-Bold';
          font-size: 18px;
        }
      }
      .workfile-client-content-item {
        font-family: 'CircularStd-Book';
        font-size: 18px;
      }
    }
    .workfile-client-title {
          span{
            font-size: 21px !important;             
            font-family: 'CircularStd-Bold';
          }
      }
  }
  @media (min-width: 769px) and (max-width: 1367px) and (orientation: landscape) {
    
    .workfile-client-content{
      font-size: 18px !important;
      .workfile-client-content-item.bold {
        span.bold {
          font-family: 'CircularStd-Bold';
          font-size: 18px;
        }
      }
      .workfile-client-content-item {
        font-family: 'CircularStd-Book';
        font-size: 18px;
      }
    }
    .workfile-client-title {
          span{
            font-size: 21px !important;             
            font-family: 'CircularStd-Bold';
          }
      }
  }
</style>