<script setup>
import { ref, onMounted, watch } from "vue";

const props = defineProps({
  vehicle: Object
});
const logo = ref('');
watch(() => props.vehicle, () => {

});

onMounted(() => {
  if( props.vehicle.svgImage && props.vehicle.svgImage.length > 0){
    logo.value = props.vehicle.svgImage;
  }else{
    logo.value = props.vehicle.image;
  }
});
</script>

<template>
    <div class="workfile-vehicle-logo">
      <img :src="logo">
    </div>
</template>

<style lang="scss">
.workfile-vehicle-logo{
  img{
    max-height:90px;
    max-width: 90px;
    width: auto;
    height: auto;
    object-fit: contain;
  }
  @media only screen and (max-width: 1366px) {
    padding-left: 20px;
  }
  padding-left: 30px;

}

@media (min-width: 577px) and (max-width: 1280px) and (orientation: portrait){
  .workfile-vehicle-logo{
    margin-top: -25px;
    margin-left: -3px;
    img{
      max-height:50px;
      max-width: 50px;
      width: auto;
      height: auto;
      object-fit: contain;
    }
  }
}
@media (min-width: 769px) and (max-width: 1367px) and (orientation: landscape) {
  .workfile-vehicle-logo{
    margin-left: 22px;
    img{
      max-height:50px;
      max-width: 50px;
      width: auto;
      height: auto;
      object-fit: contain;
      margin-top: -10px;
    }
  }
}

@media (min) {
  
}
</style>