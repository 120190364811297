<script setup>
import { watch,ref } from "vue";
import LocalStorage from '@/controller/LocalStorage.js';

const props = defineProps({
  vehicle: Object,
  or: String
});

watch(() => props.vehicle, () => {

});
const userColor = ref(LocalStorage.getColor());
const orderIcon = "/assets/workfiles/3.svg";
</script>

<template>
    <div class="workfile-vehicle-info">
      <div class="or-container">
        <div class="workfile-or" 
          v-if="props.or"          
          v-popoverr 
          data-bs-toggle="popover" 
          data-bs-trigger="hover"
          :data-bs-content="props.or"
          data-bs-placement="top"
          data-bs-html="false"
          >
            <img :src="orderIcon" style="width:22.74px;"/>
            <span>Nº {{ props.or }}</span>
        </div>
      </div>
        <div class="workfile-vehicle-title">
            <span class="bold">{{ props.vehicle?.brand + ' ' + props.vehicle?.model }}</span>
        </div>
        <div class="workfile-vehicle-content">
            <div class="workfile-vehicle-content-item plate">
                <span class="bold">{{ props.vehicle?.plate }}</span>
            </div>
            <div class="workfile-vehicle-content-item vin">
                <span>{{  props.vehicle?.vin }}</span>
            </div>
            <div class="workfile-vehicle-content-item">
                <span>{{ props.vehicle?.mileage }}</span>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.workfile-vehicle-info{
  @media only screen and (max-width: 1366px) {
    padding-left: 3rem;
    margin-bottom: 0.5rem;
  }
  padding-top: 5px;
  .workfile-or{
    font-family: 'CircularStd-Book';
    background-color: #363636;
    border-radius: 15px;
    color: white;
    padding: 2px 15px;
    max-width:165px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: fit-content;
    margin-bottom: 10px;
    img{
      display: none;
    }
  }
  .workfile-or span{
    max-width: 175px;
  }
  .workfile-vehicle-title{
    font-family: 'CircularStd-Bold';
    font-size: 16px;
    padding-bottom: 5px;
  }
  .bold{
    font-weight: bold;
  }
  .workfile-vehicle-title,
  .workfile-vehicle-content{
    line-height: 1.3;
  }
  .plate{
    font-size: 16px;
  }
  .vin{
    font-size: 14px;
  }
}


@media (max-width: 576px) {
  .workfile-vehicle-info{
    margin-left: -35px;
    width: 90dvw;
    .or-container{
      width: 250px;
      max-width: 250px;
      min-width: 150px;
      height: 50px;
      margin-top: -15dvh;
      padding-top: 3px;
      left: -30px;
      .workfile-or {
        background-color: v-bind(userColor);
        border-radius: 0px 0px 19px 19px;
        padding-right: 10px;
        height: 8dvh;
        display: flex;
        align-items: end;
        padding-bottom: 15px;
        padding-right: 15px;
        img{
          display: inline-block;
          margin-right: 10px;
        }
        span {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
     }
    }
  }
  .workfile-vehicle-title{
    font-size: 20px !important;
    margin-top: 6dvh;
  }
  .workfile-vehicle-content{
    span{
      font-size: 20px !important;
      font-family: 'CircularStd-Book';
    }
  }
  .workfile-vehicle-title,
  .workfile-vehicle-content{
    line-height: 1.2 !important;
    font-family: 'CircularStd-Bold';
  }
}

@media (min-width: 577px) and (max-width: 1366px){
  .workfile-vehicle-info{
    top: -67px;
    .workfile-vehicle-title{
      .bold{
        font-family: 'CircularStd-Bold';
        font-size: 18px;
      }
    }
    .workfile-vehicle-content-item{
      span{
        word-wrap: break-word;
      }
    }
    .workfile-vehicle-content-item.plate {
        font-family: 'CircularStd-Bold';
        font-size: 18px;
    }
    .workfile-vehicle-content-item.vin {
      font-family: 'CircularStd-Book';
      font-size: 18px;
      }
    .or-container{
      width: 127px;
      height: 50px;
      .workfile-or{
        @media (min-width:577px) and (max-width: 686px){
           margin-left: 50px !important;
           max-width: 110px !important;
        }
      margin-top: -105px;
      max-width:127px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: fit-content;
      height: 33px;
      margin-left: 65px;
      border-radius: 21px !important;
      padding-top: 3px;
        span{
          font-size: 14px;
          font-family: 'CircularStd-Book';
        }
      }
    }
  }
}
</style>