<script setup>
import { ref,watch } from "vue";
import { useI18n } from "vue-i18n";
import LocalStorage from '@/controller/LocalStorage.js';
import defaultImage from '../../../../public/assets/default-car.jpg';
import IconButton from "@/stories/Buttons/iconButton/IconButton.vue";
import { propsToAttrMap } from "@vue/shared";

const props = defineProps({
  item: Object,
  property: String,
  type: String
});

const emit = defineEmits("updateVehicleList");
const userColor = ref(LocalStorage.getColor());
const { t } = useI18n();
const isLoadedImg = ref(false);
const svg = 
      `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 41.349 30.89">
        <g id="Grupo_2278" data-name="Grupo 2278" transform="translate(1.5 1.5)">
          <g id="Grupo_591" data-name="Grupo 591" transform="translate(0 0)">
            <path id="Trazado_376" data-name="Trazado 376" d="M367.944,381.889c0,3.351,6.3,13.945,19.175,13.945s19.174-10.594,19.174-13.945S400,367.944,387.119,367.944s-19.175,10.593-19.175,13.945" transform="translate(-367.944 -367.944)" fill="none" stroke="#c8cfd9" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
          </g>
          <circle id="Elipse_546" data-name="Elipse 546" cx="6.973" cy="6.973" r="6.973" transform="translate(12.202 6.973)" fill="none" stroke="#c8cfd9" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
        </g>
      </svg>` 
      ;
let date = "";
let carImage = null;
let link = null
let doubleLink = null
let btnState = null

if (props.property == 'date' || props.property == 'created') {
  date = new Date(props.item[props.property] * 1000).toLocaleString([], { dateStyle: 'short', timeStyle: 'short' });
}
if (props.property == 'image') {
  if (props.item && props.item['image'] != 'null' && props.item['image'] != '') {
    let propImage = props.item['image']
    watch(() => propImage,
    (image) => {
      const img = new Image();
      img.onload = () => (isLoadedImg.value = true);
      img.src = image;
    },
    { immediate: true })
  } else {
    carImage = defaultImage;
    isLoadedImg.value = true;
  }
} 
if (props.property == 'linkId' && !props.type != 'doubleLink') {
  link = '/' + props.item['linkId']
}
if(props.property == 'linkId' && props.type == 'doubleLink') {
  doubleLink = props.item['linkId'];
}
if (props.property == 'link' && props.type == 'file') {
  link = props.item['link'];
}
if (props.property == 'link' && props.type == 'notificationLink') {
  if(props.item.type == 3 ){
    link = '/invitation/' + props.item.data.invitationId
  }else{
    link = '/workfiles';
  }
}

function updateTableVehicle() {
  emit('updateVehicleList', props.item.id);
}

</script>

<template>
  <div class="custom-table-cell">
    <div class="cell-img" v-if="property == 'image'">
      <img :src="carImage" v-if="carImage" />
      <img :src="item[property]" v-else />
    </div> 
    <div class="cell" v-else-if="property == 'date' || property == 'created'">
      {{ date }}
    </div>
    <div class="cell" v-else-if="property == 'status' && type == 'statusLog' && (item.wfStatus == 99 || item.wfStatus == 100)">
      {{ $t("workfiles.state.quotation." + item[property]) }}
    </div>
    <div class="cell" v-else-if="property == 'status' && type == 'statusLog'">
      {{ $t("workfiles.state.primary." + item[property]) }}
    </div>
    <div class="cell" v-else-if="property == 'status'">
      {{ $t("workfiles.state." + item[property]) }}
    </div>
    <div class="cell" v-else-if="property == 'status_invitation'">
      {{ $t("invitations.states." + item[property]) }}
    </div>
    <div class="cell" v-else-if="property == 'plate_vin'">
      {{ item['plate'] }}
    </div>
    <div class="cell" v-else-if="property == 'message' && type == 'notificationInterpolation'">
      <i18n-t :keypath="'notifications'+'.'+item.message" tag="p">
          <template #or>
          <span class="notification-data--bold">{{ item.or }}</span>
          </template>
          <template #brand>
          <span class="notification-data--bold">{{ item.brand }}</span>
          </template>
          <template #model>
          <span class="notification-data--bold">{{ item.model }}</span>
          </template>
          <template #plate>
          <span class="notification-data--bold">{{ item.plate }}</span>
          </template>
          <template #vin>
          <span class="notification-data--bold">{{ item.vin }}</span>
          </template>
      </i18n-t>
    </div>
    <div class="cell text-rights cell-btn w-100" v-else-if="property == 'btnVehicleState'">
      <button v-if="item['has_vehicle'] == true" class="table-btn w-100" @click="updateTableVehicle()">
        {{ t("field.added") }}
      </button>
      <button v-else class="table-btn-inactive w-100" @click="updateTableVehicle()">
        {{ t("field.add") }} 
      </button>
    </div>
    <div class="cell text-rights cell-link-file w-100" v-else-if="link && type == 'file'">
      <a :href="link" style="font-size: 20px;">
          <font-awesome-icon icon="fa-solid fa-file-pdf" />
      </a> 
    </div>
    <div class="cell text-rights cell-link w-100" v-else-if="link">
      <button v-if="type == 'view'" class="table-btn w-100">
        <router-link :to="link" class="router-link">
          {{ t("field.see") }}
        </router-link>
      </button>
      <div class="cell text-rights cell-link-file w-100" v-else-if="type == 'viewInvitation'">
        <router-link :to="link" v-html="svg"></router-link> 
      </div>
      <button v-if="type == 'edit'" class="table-btn w-100">
        <router-link :to="link" class="router-link">
          {{ t("field.edit") }}
        </router-link>
      </button>
      <button v-if="type == 'delete'" class="table-btn w-100">
        <router-link :to="link" class="router-link">
          {{ t("field.delete") }}
        </router-link>
      </button>    
      <button v-if="type == 'add'" class="table-btn w-100">
        <router-link :to="link" class="router-link">
          {{ t("field.add") }}
        </router-link>
      </button>
      <button v-if="type == 'notificationLink'" class="table-btn w-100">
        <router-link :to="link" class="router-link">
          {{ t("field.see") }}
        </router-link>
      </button>   
      <template v-for="(item, index) in doubleLink">
        <button v-if="type == 'doubleLink'" class="table-btn double-btn w-50">
          <router-link :to="item" class="router-link">
            {{ $t(`field.${index}`) }}
          </router-link>
        </button>    
      </template>
    </div>
    <div class="cell" v-else>
      {{ item[property] }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom-table-cell {
  align-items: center;
  display: flex;
  min-height: 32px;
  .cell {
    // white-space: nowrap;
    text-overflow: unset;
    // overflow: hidden;
    word-break: break-word;
  }
  .cell-img {
    min-height: 32px;
    min-width: 85px;
    img {
      max-height: 32px;
    }
  }
  .table-btn {
    background-color: v-bind(userColor);
    color: white;
    border: none;
    padding: 2px 25px;
    border-radius: 0.3em;
  }
  .table-btn-inactive {
    background-color: #D9D9D9;
    color: #6e6c6c;
    border: none;
    padding: 2px 25px;
    border-radius: 0.3em;
  }
  .double-btn {
    padding: 0 5px;
  }
  .cell-link {
    text-align: right;
    gap: 0.5rem;
  }
  .router-link {
    text-decoration: none;
    font-weight: 600; 
    color: white;
    display: inline-block;
    width: 100%;
  }
}
@media (max-width: 576px) {
 .custom-table-cell{
    max-width: 70dvw;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
 }
}

</style>
