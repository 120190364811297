<script setup>
import { ref, watch } from "vue";
import CustomTableCell from '@/components/common/utils/CustomTableCell.vue';
import CustomTablePaginator from '@/components/common/utils/CustomTablePaginator.vue';
import CustomTableSearch from '@/components/common/utils/CustomTableSearch.vue';

const props = defineProps({
  items: Object,
  headers: Object,
  total: Number,
  page: Number,
  isLoading: Boolean,
  limit: Number,
  search: Object, // enabled, position, width
  permissions: Object
});

const emit = defineEmits(["callLoadPage","searching","callUpdateVehicle"]);
const start = ref(0);
const pagNumber = ref(1);
const numCells = Object.keys(props.items).length;
const pagLimit = ref(props.limit ?? 12);
const reRender = ref(1);

function loadTablePage(index) {
  pagNumber.value = index;
  start.value = (index - 1) * pagLimit.value;
  emit('callLoadPage', index - 1);
}
function isSearching(txt) {
  emit('searching', txt);
}

function updateTableVehicle(index) {
  emit('callUpdateVehicle', index);
}

watch(() => props.total, () => { reRender.value++; });

</script>

<template>
  <div class="custom-table-wrapper">
    <div class="custom-table-search" v-if="props.search && props.search.enabled">
      <CustomTableSearch 
        :position="props.search && props.search.position" 
        :width="props.search && props.search.width"
        @searching="(txt) => (isSearching(txt))"/>
    </div>
    <table class="custom-table table-header table">
      <thead>
        <tr>
          <th v-for="h in headers">
            <div>{{ h.text }}</div>
          </th>
        </tr>
      </thead>
    </table>
    <table class="custom-table table-body table">
      <tbody>
        <template v-if="isLoading">
          <tr>
            <td 
              class="w-100 text-center td-loading"
              :colspan="numCells">
                <div class="loading-cell">
                  {{ $t('field.loading') }}
                </div>
              </td>
          </tr>
        </template>
        <template v-else>
          <tr v-if="items.length>0" v-for="item in items">
            <td v-for="v in headers">
              <CustomTableCell :item="item" 
              :property="v.value" 
              :type="v.type"
              @updateVehicleList="updateTableVehicle" />
            </td>
          </tr>
          <tr v-else>
            <div class="no-data text-center">
              {{ $t('field.no_data') }}
            </div>
          </tr>
        </template>
      </tbody>
    </table>
    <div class="custom-table-pagination">
      <CustomTablePaginator
        :start="start"
        :total="props.total"
        :pagNumber="props.page"
        :limit="pagLimit"
        @loadPage="loadTablePage"
        :key="reRender"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom-table {
  border-collapse: collapse;
  margin-bottom: 0px;
  font-size: 0.9em;
  min-width: 400px;
  background-color: white;
  color: #6e6c6c;
  border-collapse: collapse;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
  &.table-header {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0.3em;
    border-top-left-radius: 0.3em;
  }
  &.table-body{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0.3em;
    border-bottom-left-radius: 0.3em;
  }
  .td-loading {
    height: 100%;
    background-color: whitesmoke;
    .loading-cell {
      height: 400px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  th,
  tr {
    padding: 4px 15px;
  }
  tr td {
    padding: 4px 15px;
    border-bottom: 2px solid white;
    border: solid 1px #d8d8d8;
    border-left: 0;
    border-right: 0;
  }
  tr td {
    border-bottom: solid 1px #d8d8d8;
  }
  tr td:last-child  {
    border-right: solid 1px #d8d8d8;
  }
  tr td:first-child  {
    border-left: solid 1px #d8d8d8;
  }
  thead th {
    background-color: #e5e5e5;
    color: #504e4e;
    font-weight: 600;
    height: 45px;
    &:first-child  {
      border-left: solid 1px #d8d8d8;
    }
    &:last-child  {
      border-right: solid 1px #d8d8d8;
    }
    &>div {
      align-items: center;
      display: flex;
      height: 100%;
    }
  }
  tbody {
    tr {
      border: 1px solid #efeff1;
      transition: background-color 150ms ease-out;
    }
  }
  tbody tr:nth-child(2n) td{
    background-color: whitesmoke;
  }
  tbody td {
    background-clip: padding-box
  }
}

@media (max-width: 576px) {
.custom-table{
  min-width: inherit;
  }
}

</style>
